<template>
    <div v-if="chartData" class="container">
        <bar-chart
            :width="700"
            :height="450"
            v-bind:chartData="chartData"
            :options="options"
        />
    </div>
</template>
<script>
import BarChart from './Plots/BarChart.vue'
import mixins from './Plots/mixins'

export default {
    components: { BarChart },
    mixins: [mixins.plotDataMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        maintainAspectRatio: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            chartData: null,
            options: {
                title: {
                    display: true,
                    text: 'Items waiting for raw material',
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                            gridLines: {
                                display: true,
                            },
                            scaleLabel: {
                                display: true,
                                fontStyle: 'italic',
                                labelString: 'Queue size [items]',
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            scaleLabel: {
                                display: true,
                                fontStyle: 'italic',
                                labelString: 'Day',
                            },
                        },
                    ],
                },
                legend: {
                    display: true,
                },
                responsive: true,
                maintainAspectRatio: this.maintainAspectRatio,
            },
        }
    },
    methods: {
        initChartData() {
            let { stats, labels } = this.getRecentStats()
            this.chartData = {
                labels: labels,
                datasets: [
                    {
                        label: 'Queue size at the end of the day',
                        data: stats.map((s) => s['queue size last']),
                        backgroundColor: 'rgb(0, 128, 128, 0.5)',
                        steppedLine: 'middle',
                        pointRadius: 0,
                    },
                    {
                        label: 'Maximum queue size of the day',
                        hidden: true,
                        data: stats.map((s) => s['queue size max']),
                        backgroundColor: 'rgb(0, 128, 0, 0.5)',
                        steppedLine: 'middle',
                        pointRadius: 0,
                    },
                    {
                        label: 'Average queue size of the day',
                        hidden: true,
                        data: stats.map((s) => s['queue size max']),
                        backgroundColor: 'rgb(128, 0, 0, 0.5)',
                        steppedLine: 'middle',
                        pointRadius: 0,
                    },
                ],
            }
        },
    },
    mounted() {
        this.initChartData()
    },
    watch: {
        data: function () {
            if (!!this.data) {
                this.initChartData()
            }
        },
    },
}
</script>
