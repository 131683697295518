var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card shadow-sm"},[_c('div',{staticClass:"card-header"},[_vm._v(" Workshop "),_c('delete-unit',{ref:"deleteBtn",attrs:{"data":_vm.data},on:{"deletedunit":function($event){return _vm.$emit('deletedunit')},"home":function($event){return _vm.$emit('home')}}})],1),_c('div',{staticClass:"card-body small font-weight-light"},[(_vm.data)?_c('div',[_c('name-field',{attrs:{"data":_vm.data},on:{"redrawnetwork":function($event){return _vm.$emit('redrawnetwork')}}}),_c('queue-policy',{attrs:{"data":_vm.data,"paramName":"queue_policy","waitingSubmitResponse":_vm.waitingSubmitResponse},on:{"contractChange":_vm.submitQueuePolicy}}),_c('div',{staticClass:"mt-2"},[_c('label',[_vm._v("Setup time per lot in minutes:")]),(!_vm.isGamePlay)?_c('b-input-group',[_c('b-form-select',{attrs:{"options":[
                            { value: 'constant', text: 'Constant' },
                            {
                                value: 'normal',
                                text: 'Normal Distribution',
                            } ]},model:{value:(_vm.data.params.machine.lot_setup_time_type),callback:function ($$v) {_vm.$set(_vm.data.params.machine, "lot_setup_time_type", $$v)},expression:"data.params.machine.lot_setup_time_type"}}),_c('b-input-group-append',[_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Show'),expression:"'Show'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"value":"false","disabled":"","switch":""}})],1),_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"value":"false","disabled":"","switch":""}})],1)],1)],1):_vm._e()],1),(
                    _vm.data.params.machine.lot_setup_time_type === 'constant'
                )?_c('div',{staticClass:"mt-2"},[(
                        _vm.data.layout.params.lot_setup_time_constant.show ||
                        !_vm.isGamePlay
                    )?_c('b-input-group',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Lot setup time in minutes'),expression:"'Lot setup time in minutes'",modifiers:{"hover":true,"top":true}}]},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('span',{staticClass:"mx-1"},[_c('b-icon',{attrs:{"icon":"clock","aria-hidden":"true"}})],1)]),_c('b-form-input',{attrs:{"disabled":_vm.isGamePlay,"number":true,"type":"number","min":"0","step":"0.05"},model:{value:(
                            _vm.data.params.machine.lot_setup_time_constant
                        ),callback:function ($$v) {_vm.$set(_vm.data.params.machine, "lot_setup_time_constant", $$v)},expression:"\n                            data.params.machine.lot_setup_time_constant\n                        "}}),(!_vm.isGamePlay)?_c('b-input-group-append',[_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Show'),expression:"'Show'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"switch":""},model:{value:(
                                    _vm.data.layout.params
                                        .lot_setup_time_constant.show
                                ),callback:function ($$v) {_vm.$set(_vm.data.layout.params
                                        .lot_setup_time_constant, "show", $$v)},expression:"\n                                    data.layout.params\n                                        .lot_setup_time_constant.show\n                                "}})],1),_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"disabled":"","switch":""},model:{value:(
                                    _vm.data.layout.params
                                        .lot_setup_time_constant.edit
                                ),callback:function ($$v) {_vm.$set(_vm.data.layout.params
                                        .lot_setup_time_constant, "edit", $$v)},expression:"\n                                    data.layout.params\n                                        .lot_setup_time_constant.edit\n                                "}})],1)],1):_vm._e()],1):_vm._e(),(
                        !_vm.data.layout.params.lot_setup_time_constant.show &&
                        _vm.isGamePlay
                    )?_c('div',[_c('em',[_vm._v(" One or more parameters have been hidden by the game creator, try deducing these through analysing the simulation units. ")])]):_vm._e()],1):(
                    _vm.data.params.machine.lot_setup_time_type === 'normal'
                )?_c('div',{staticClass:"mt-2"},[(
                        _vm.data.layout.params.lot_setup_time_avg.show ||
                        !_vm.isGamePlay
                    )?_c('b-input-group',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                        'The average lot setup time in minutes'
                    ),expression:"\n                        'The average lot setup time in minutes'\n                    ",modifiers:{"hover":true,"top":true}}]},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('span',{staticClass:"mx-1"},[_vm._v("μ")])]),_c('b-form-input',{attrs:{"disabled":_vm.isGamePlay,"number":true,"type":"number","min":"0","step":"0.05"},model:{value:(_vm.data.params.machine.lot_setup_time_avg),callback:function ($$v) {_vm.$set(_vm.data.params.machine, "lot_setup_time_avg", $$v)},expression:"data.params.machine.lot_setup_time_avg"}}),(!_vm.isGamePlay)?_c('b-input-group-append',[_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Show'),expression:"'Show'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"switch":""},model:{value:(
                                    _vm.data.layout.params.lot_setup_time_avg
                                        .show
                                ),callback:function ($$v) {_vm.$set(_vm.data.layout.params.lot_setup_time_avg
                                        , "show", $$v)},expression:"\n                                    data.layout.params.lot_setup_time_avg\n                                        .show\n                                "}})],1),_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"disabled":"","switch":""},model:{value:(
                                    _vm.data.layout.params.lot_setup_time_avg
                                        .edit
                                ),callback:function ($$v) {_vm.$set(_vm.data.layout.params.lot_setup_time_avg
                                        , "edit", $$v)},expression:"\n                                    data.layout.params.lot_setup_time_avg\n                                        .edit\n                                "}})],1)],1):_vm._e()],1):_vm._e(),(
                        _vm.data.layout.params.lot_setup_time_sigma.show ||
                        !_vm.isGamePlay
                    )?_c('b-input-group',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                        'The standard deviation of the lot setup time in minutes'
                    ),expression:"\n                        'The standard deviation of the lot setup time in minutes'\n                    ",modifiers:{"hover":true,"top":true}}],staticClass:"mt-2"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('span',{staticClass:"mx-1"},[_vm._v("σ")])]),_c('b-form-input',{attrs:{"disabled":_vm.isGamePlay,"number":true,"type":"number","min":"0","step":"0.05"},model:{value:(_vm.data.params.machine.lot_setup_time_sigma),callback:function ($$v) {_vm.$set(_vm.data.params.machine, "lot_setup_time_sigma", $$v)},expression:"data.params.machine.lot_setup_time_sigma"}}),(!_vm.isGamePlay)?_c('b-input-group-append',[_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Show'),expression:"'Show'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"switch":""},model:{value:(
                                    _vm.data.layout.params.lot_setup_time_sigma
                                        .show
                                ),callback:function ($$v) {_vm.$set(_vm.data.layout.params.lot_setup_time_sigma
                                        , "show", $$v)},expression:"\n                                    data.layout.params.lot_setup_time_sigma\n                                        .show\n                                "}})],1),_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"disabled":"","switch":""},model:{value:(
                                    _vm.data.layout.params.lot_setup_time_sigma
                                        .edit
                                ),callback:function ($$v) {_vm.$set(_vm.data.layout.params.lot_setup_time_sigma
                                        , "edit", $$v)},expression:"\n                                    data.layout.params.lot_setup_time_sigma\n                                        .edit\n                                "}})],1)],1):_vm._e()],1):_vm._e(),(
                        _vm.isGamePlay &&
                        (!_vm.data.layout.params.lot_setup_time_sigma.show ||
                            !_vm.data.layout.params.lot_setup_time_avg.show)
                    )?_c('div',[_c('em',[_vm._v(" One or more parameters have been hidden by the game creator, try deducing these through analysing the simulation units. ")])]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"mt-2"},[_c('label',[_vm._v("Item processing time in minutes:")]),(!_vm.isGamePlay)?_c('b-input-group',[_c('b-form-select',{attrs:{"options":[
                            { value: 'constant', text: 'Constant' },
                            {
                                value: 'normal',
                                text: 'Normal Distribution',
                            } ]},model:{value:(
                            _vm.data.params.machine.item_processing_time_type
                        ),callback:function ($$v) {_vm.$set(_vm.data.params.machine, "item_processing_time_type", $$v)},expression:"\n                            data.params.machine.item_processing_time_type\n                        "}}),_c('b-input-group-append',[_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Show'),expression:"'Show'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"value":"false","disabled":"","switch":""}})],1),_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"value":"false","disabled":"","switch":""}})],1)],1)],1):_vm._e()],1),(
                    _vm.data.params.machine.item_processing_time_type ===
                    'constant'
                )?_c('div',{staticClass:"mt-2"},[(
                        _vm.data.layout.params.item_processing_time_constant
                            .show || !_vm.isGamePlay
                    )?_c('b-input-group',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                        'The item processing time in minutes'
                    ),expression:"\n                        'The item processing time in minutes'\n                    ",modifiers:{"hover":true,"top":true}}]},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('span',{staticClass:"mx-1"},[_c('b-icon',{attrs:{"icon":"clock","aria-hidden":"true"}})],1)]),_c('b-form-input',{attrs:{"disabled":_vm.isGamePlay,"number":true,"type":"number","min":"0","step":"0.05"},model:{value:(
                            _vm.data.params.machine
                                .item_processing_time_constant
                        ),callback:function ($$v) {_vm.$set(_vm.data.params.machine
                                , "item_processing_time_constant", $$v)},expression:"\n                            data.params.machine\n                                .item_processing_time_constant\n                        "}}),(!_vm.isGamePlay)?_c('b-input-group-append',[_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Show'),expression:"'Show'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"switch":""},model:{value:(
                                    _vm.data.layout.params
                                        .item_processing_time_constant.show
                                ),callback:function ($$v) {_vm.$set(_vm.data.layout.params
                                        .item_processing_time_constant, "show", $$v)},expression:"\n                                    data.layout.params\n                                        .item_processing_time_constant.show\n                                "}})],1),_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"disabled":"","switch":""},model:{value:(
                                    _vm.data.layout.params
                                        .item_processing_time_constant.edit
                                ),callback:function ($$v) {_vm.$set(_vm.data.layout.params
                                        .item_processing_time_constant, "edit", $$v)},expression:"\n                                    data.layout.params\n                                        .item_processing_time_constant.edit\n                                "}})],1)],1):_vm._e()],1):_vm._e(),(
                        !_vm.data.layout.params.item_processing_time_constant
                            .show && _vm.isGamePlay
                    )?_c('div',[_c('em',[_vm._v(" One or more parameters have been hidden by the game creator, try deducing these through analysing the simulation units. ")])]):_vm._e()],1):(
                    _vm.data.params.machine.item_processing_time_type ===
                    'normal'
                )?_c('div',{staticClass:"mt-2"},[(
                        _vm.data.layout.params.item_processing_time_avg.show ||
                        !_vm.isGamePlay
                    )?_c('b-input-group',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                        'The average item processing time in minutes'
                    ),expression:"\n                        'The average item processing time in minutes'\n                    ",modifiers:{"hover":true,"top":true}}]},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('span',{staticClass:"mx-1"},[_vm._v("μ")])]),_c('b-form-input',{attrs:{"disabled":_vm.isGamePlay,"number":true,"type":"number","min":"0","step":"0.05"},model:{value:(
                            _vm.data.params.machine.item_processing_time_avg
                        ),callback:function ($$v) {_vm.$set(_vm.data.params.machine, "item_processing_time_avg", $$v)},expression:"\n                            data.params.machine.item_processing_time_avg\n                        "}}),(!_vm.isGamePlay)?_c('b-input-group-append',[_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Show'),expression:"'Show'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"switch":""},model:{value:(
                                    _vm.data.layout.params
                                        .item_processing_time_avg.show
                                ),callback:function ($$v) {_vm.$set(_vm.data.layout.params
                                        .item_processing_time_avg, "show", $$v)},expression:"\n                                    data.layout.params\n                                        .item_processing_time_avg.show\n                                "}})],1),_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"disabled":"","switch":""},model:{value:(
                                    _vm.data.layout.params
                                        .item_processing_time_avg.edit
                                ),callback:function ($$v) {_vm.$set(_vm.data.layout.params
                                        .item_processing_time_avg, "edit", $$v)},expression:"\n                                    data.layout.params\n                                        .item_processing_time_avg.edit\n                                "}})],1)],1):_vm._e()],1):_vm._e(),(
                        _vm.data.layout.params.item_processing_time_sigma
                            .show || !_vm.isGamePlay
                    )?_c('b-input-group',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                        'The standard deviation of the item processing time in minutes'
                    ),expression:"\n                        'The standard deviation of the item processing time in minutes'\n                    ",modifiers:{"hover":true,"top":true}}],staticClass:"mt-2"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('span',{staticClass:"mx-1"},[_vm._v("σ")])]),_c('b-form-input',{attrs:{"disabled":_vm.isGamePlay,"number":true,"type":"number","min":"0","step":"0.05"},model:{value:(
                            _vm.data.params.machine.item_processing_time_sigma
                        ),callback:function ($$v) {_vm.$set(_vm.data.params.machine, "item_processing_time_sigma", $$v)},expression:"\n                            data.params.machine.item_processing_time_sigma\n                        "}}),(!_vm.isGamePlay)?_c('b-input-group-append',[_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Show'),expression:"'Show'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"switch":""},model:{value:(
                                    _vm.data.layout.params
                                        .item_processing_time_sigma.show
                                ),callback:function ($$v) {_vm.$set(_vm.data.layout.params
                                        .item_processing_time_sigma, "show", $$v)},expression:"\n                                    data.layout.params\n                                        .item_processing_time_sigma.show\n                                "}})],1),_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"disabled":"","switch":""},model:{value:(
                                    _vm.data.layout.params
                                        .item_processing_time_sigma.edit
                                ),callback:function ($$v) {_vm.$set(_vm.data.layout.params
                                        .item_processing_time_sigma, "edit", $$v)},expression:"\n                                    data.layout.params\n                                        .item_processing_time_sigma.edit\n                                "}})],1)],1):_vm._e()],1):_vm._e(),(
                        _vm.isGamePlay &&
                        (!_vm.data.layout.params.item_processing_time_sigma
                            .show ||
                            !_vm.data.layout.params.item_processing_time_avg
                                .show)
                    )?_c('div',[_c('em',[_vm._v(" One or more parameters have been hidden by the game creator, try deducing these through analysing the simulation units. ")])]):_vm._e()],1):_vm._e(),(_vm.showBreakdownAllowed)?_c('div',{staticClass:"mt-2"},[_c('label',[_vm._v("Machine breakdown probability:")]),_c('b-input-group',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                        'Daily probability of breakdowns'
                    ),expression:"\n                        'Daily probability of breakdowns'\n                    ",modifiers:{"hover":true,"top":true}}]},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-icon',{attrs:{"icon":"dice2-fill","aria-hidden":"true"}})],1),_c('b-form-input',{attrs:{"disabled":_vm.isGamePlay,"number":true,"type":"number","min":"0","step":"0.05"},on:{"change":_vm.onBreakdownProbaChange},model:{value:(_vm.data.params.machine.breakdown.proba),callback:function ($$v) {_vm.$set(_vm.data.params.machine.breakdown, "proba", $$v)},expression:"data.params.machine.breakdown.proba"}}),(!_vm.isGamePlay)?_c('b-input-group-append',[_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Show'),expression:"'Show'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"switch":""},model:{value:(_vm.data.layout.params.breakdown.show),callback:function ($$v) {_vm.$set(_vm.data.layout.params.breakdown, "show", $$v)},expression:"data.layout.params.breakdown.show"}})],1),_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"disabled":"","switch":""},model:{value:(_vm.data.layout.params.breakdown.edit),callback:function ($$v) {_vm.$set(_vm.data.layout.params.breakdown, "edit", $$v)},expression:"data.layout.params.breakdown.edit"}})],1)],1):_vm._e()],1),(_vm.data.params.machine.breakdown.proba > 0)?_c('div',{staticClass:"mt-2"},[_c('label',[_vm._v("Repair time in minutes:")]),(!_vm.isGamePlay)?_c('b-input-group',[_c('b-form-select',{attrs:{"options":[
                                { value: 'constant', text: 'Constant' },
                                {
                                    value: 'normal',
                                    text: 'Normal Distribution',
                                } ]},model:{value:(_vm.data.params.machine.breakdown_type),callback:function ($$v) {_vm.$set(_vm.data.params.machine, "breakdown_type", $$v)},expression:"data.params.machine.breakdown_type"}}),_c('b-input-group-append',[_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Show'),expression:"'Show'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"value":"false","disabled":"","switch":""}})],1),_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"value":"false","disabled":"","switch":""}})],1)],1)],1):_vm._e(),(
                            _vm.data.params.machine.breakdown_type ===
                            'constant'
                        )?_c('div',{staticClass:"mt-2"},[(
                                _vm.data.layout.params.breakdown_constant
                                    .show || !_vm.isGamePlay
                            )?_c('b-input-group',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Repair time in minutes'),expression:"'Repair time in minutes'",modifiers:{"hover":true,"top":true}}]},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('span',{staticClass:"mx-1"},[_c('b-icon',{attrs:{"icon":"clock","aria-hidden":"true"}})],1)]),_c('b-form-input',{attrs:{"disabled":_vm.isGamePlay,"number":true,"type":"number","min":"0","step":"0.05"},model:{value:(
                                    _vm.data.params.machine.breakdown_constant
                                ),callback:function ($$v) {_vm.$set(_vm.data.params.machine, "breakdown_constant", $$v)},expression:"\n                                    data.params.machine.breakdown_constant\n                                "}}),(!_vm.isGamePlay)?_c('b-input-group-append',[_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Show'),expression:"'Show'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"switch":""},model:{value:(
                                            _vm.data.layout.params
                                                .breakdown_constant.show
                                        ),callback:function ($$v) {_vm.$set(_vm.data.layout.params
                                                .breakdown_constant, "show", $$v)},expression:"\n                                            data.layout.params\n                                                .breakdown_constant.show\n                                        "}})],1),_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"disabled":"","switch":""},model:{value:(
                                            _vm.data.layout.params
                                                .breakdown_constant.edit
                                        ),callback:function ($$v) {_vm.$set(_vm.data.layout.params
                                                .breakdown_constant, "edit", $$v)},expression:"\n                                            data.layout.params\n                                                .breakdown_constant.edit\n                                        "}})],1)],1):_vm._e()],1):_vm._e(),(
                                !_vm.data.layout.params.breakdown_constant
                                    .show && _vm.isGamePlay
                            )?_c('div',[_c('em',[_vm._v(" One or more parameters have been hidden by the game creator, try deducing these through analysing the simulation units. ")])]):_vm._e()],1):(
                            _vm.data.params.machine.breakdown_type === 'normal'
                        )?_c('div',{staticClass:"mt-2"},[(
                                _vm.data.layout.params.breakdown_avg.show ||
                                !_vm.isGamePlay
                            )?_c('b-input-group',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                                'The average lot setup time in minutes'
                            ),expression:"\n                                'The average lot setup time in minutes'\n                            ",modifiers:{"hover":true,"top":true}}]},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('span',{staticClass:"mx-1"},[_vm._v("μ")])]),_c('b-form-input',{attrs:{"disabled":_vm.isGamePlay,"number":true,"type":"number","min":"0","step":"0.05"},model:{value:(_vm.data.params.machine.breakdown_avg),callback:function ($$v) {_vm.$set(_vm.data.params.machine, "breakdown_avg", $$v)},expression:"data.params.machine.breakdown_avg"}}),(!_vm.isGamePlay)?_c('b-input-group-append',[_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Show'),expression:"'Show'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"switch":""},model:{value:(
                                            _vm.data.layout.params.breakdown_avg
                                                .show
                                        ),callback:function ($$v) {_vm.$set(_vm.data.layout.params.breakdown_avg
                                                , "show", $$v)},expression:"\n                                            data.layout.params.breakdown_avg\n                                                .show\n                                        "}})],1),_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"disabled":"","switch":""},model:{value:(
                                            _vm.data.layout.params.breakdown_avg
                                                .edit
                                        ),callback:function ($$v) {_vm.$set(_vm.data.layout.params.breakdown_avg
                                                , "edit", $$v)},expression:"\n                                            data.layout.params.breakdown_avg\n                                                .edit\n                                        "}})],1)],1):_vm._e()],1):_vm._e(),(
                                _vm.data.layout.params.breakdown_sigma.show ||
                                !_vm.isGamePlay
                            )?_c('b-input-group',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                                'The standard deviation of the lot setup time in minutes'
                            ),expression:"\n                                'The standard deviation of the lot setup time in minutes'\n                            ",modifiers:{"hover":true,"top":true}}],staticClass:"mt-2"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('span',{staticClass:"mx-1"},[_vm._v("σ")])]),_c('b-form-input',{attrs:{"disabled":_vm.isGamePlay,"number":true,"type":"number","min":"0","step":"0.05"},model:{value:(
                                    _vm.data.params.machine.breakdown_sigma
                                ),callback:function ($$v) {_vm.$set(_vm.data.params.machine, "breakdown_sigma", $$v)},expression:"\n                                    data.params.machine.breakdown_sigma\n                                "}}),(!_vm.isGamePlay)?_c('b-input-group-append',[_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Show'),expression:"'Show'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"switch":""},model:{value:(
                                            _vm.data.layout.params
                                                .breakdown_sigma.show
                                        ),callback:function ($$v) {_vm.$set(_vm.data.layout.params
                                                .breakdown_sigma, "show", $$v)},expression:"\n                                            data.layout.params\n                                                .breakdown_sigma.show\n                                        "}})],1),_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"disabled":"","switch":""},model:{value:(
                                            _vm.data.layout.params
                                                .breakdown_sigma.edit
                                        ),callback:function ($$v) {_vm.$set(_vm.data.layout.params
                                                .breakdown_sigma, "edit", $$v)},expression:"\n                                            data.layout.params\n                                                .breakdown_sigma.edit\n                                        "}})],1)],1):_vm._e()],1):_vm._e(),(
                                _vm.isGamePlay &&
                                (!_vm.data.layout.params.breakdown_sigma.show ||
                                    !_vm.data.layout.params.breakdown_avg.show)
                            )?_c('div',[_c('em',[_vm._v(" One or more parameters have been hidden by the game creator, try deducing these through analysing the simulation units. ")])]):_vm._e()],1):_vm._e()],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"mt-2"},[_c('label',[_vm._v("Number of machines:")]),_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-icon',{attrs:{"icon":"ui-radios-grid","aria-hidden":"true"}})],1),_c('b-form-input',{attrs:{"disabled":_vm.isGamePlay,"number":true,"type":"number","min":"1"},model:{value:(_vm.data.params.machine_quantity),callback:function ($$v) {_vm.$set(_vm.data.params, "machine_quantity", $$v)},expression:"data.params.machine_quantity"}}),(!_vm.isGamePlay)?_c('b-input-group-append',[_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Show'),expression:"'Show'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"disabled":"","switch":""},model:{value:(
                                    _vm.data.layout.params.machine_quantity.show
                                ),callback:function ($$v) {_vm.$set(_vm.data.layout.params.machine_quantity, "show", $$v)},expression:"\n                                    data.layout.params.machine_quantity.show\n                                "}})],1),_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Manage'),expression:"'Manage'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"switch":""},on:{"input":_vm.onEditMachineQuantityToggle},model:{value:(
                                    _vm.data.layout.params.machine_quantity.edit
                                ),callback:function ($$v) {_vm.$set(_vm.data.layout.params.machine_quantity, "edit", $$v)},expression:"\n                                    data.layout.params.machine_quantity.edit\n                                "}})],1)],1):_vm._e()],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMachinePricesComputed),expression:"showMachinePricesComputed"}]},[_c('div',{staticClass:"mt-2"},[_c('p',[_vm._v("Purchase or retire Machines:")]),_c('label',[_vm._v("Machine purchase price:")]),_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('span',{staticClass:"mx-1"},[_vm._v("$")])]),_c('b-form-input',{attrs:{"disabled":_vm.isGamePlay,"number":true,"type":"number","min":"0"},model:{value:(_vm.data.params.machine.purchase_price),callback:function ($$v) {_vm.$set(_vm.data.params.machine, "purchase_price", $$v)},expression:"data.params.machine.purchase_price"}})],1)],1),(_vm.isGamePlay)?_c('div',{staticClass:"mt-2"},[_c('b-input-group',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                            'Number of machines to purchase'
                        ),expression:"\n                            'Number of machines to purchase'\n                        ",modifiers:{"hover":true,"top":true}}]},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-icon',{attrs:{"icon":"node-plus-fill","aria-hidden":"true"}})],1),_c('b-form-input',{attrs:{"disabled":!_vm.manageMachineQuantity,"number":true,"type":"number","min":"1"},model:{value:(_vm.purchaseMachineQuantity),callback:function ($$v) {_vm.purchaseMachineQuantity=$$v},expression:"purchaseMachineQuantity"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"success","disabled":!_vm.manageMachineQuantity ||
                                    _vm.waitingSubmitResponse},on:{"click":_vm.submitPurchaseMachines}},[_vm._v(" Submit ")])],1)],1)],1):_vm._e(),_c('div',{staticClass:"mt-2"},[_c('label',[_vm._v("Machine salvage value:")]),_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('span',{staticClass:"mx-1"},[_vm._v("$")])]),_c('b-form-input',{attrs:{"disabled":_vm.isGamePlay,"number":true,"type":"number","min":"0"},model:{value:(_vm.data.params.machine.retire_price),callback:function ($$v) {_vm.$set(_vm.data.params.machine, "retire_price", $$v)},expression:"data.params.machine.retire_price"}})],1)],1),(_vm.isGamePlay)?_c('div',{staticClass:"mt-2"},[_c('b-input-group',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                            'Number of machines to retire'
                        ),expression:"\n                            'Number of machines to retire'\n                        ",modifiers:{"hover":true,"top":true}}]},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-icon',{attrs:{"icon":"node-minus-fill","aria-hidden":"true"}})],1),_c('b-form-input',{attrs:{"disabled":!_vm.manageMachineQuantity,"number":true,"type":"number","min":"1"},model:{value:(_vm.retireMachineQuantity),callback:function ($$v) {_vm.retireMachineQuantity=$$v},expression:"retireMachineQuantity"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"success","disabled":!_vm.manageMachineQuantity ||
                                    _vm.waitingSubmitResponse},on:{"click":_vm.submitRetireMachines}},[_vm._v(" Submit ")])],1)],1)],1):_vm._e()])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }