<template>
    <div class="mt-3">
        Order:
        <draggable
            @change="onChange"
            :list="pipeline"
            group="units"
            v-bind="dragOptions"
            :move="checkMove"
        >
            <div
                v-for="element in pipeline"
                :key="element.id"
                class="list-group-item dragable"
            >
                {{ element.unit }}
            </div>
        </draggable>
    </div>
</template>

<style>
.ghost {
    opacity: 0.5;
    background: #c8ebfb !important;
}
.dragable {
    user-select: none;
}
</style>

<script>
import draggable from 'vuedraggable'
export default {
    components: {
        draggable,
    },
    props: {
        game: {
            type: Object,
            required: true,
        },
        pipelineName: {
            type: String,
            required: true,
        },
    },
    methods: {
        checkMove(event) {
            const plant = this.game.simulation.template.plant
            const unit = plant.units[event.draggedContext.element.unit]

            if (event.draggedContext.futureIndex === 0) {
                return unit.class === 'office'
            } else if (
                event.draggedContext.futureIndex ===
                this.pipeline.length - 1
            ) {
                return unit.class === 'shipment'
            } else {
                return !['office', 'shipment'].includes(unit.class)
            }
        },
        onChange() {
            this.game.simulation.template.plant.pipeline[this.pipelineName] =
                this.pipeline
            this.$emit('redrawnetwork')
        },
    },
    data() {
        return {
            pipeline:
                this.game.simulation.template.plant.pipeline[this.pipelineName],
            dragOptions: {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost',
            },
        }
    },
}
</script>
