<template>
    <div v-if="this.$route.name !== 'gamePlay'">
        <label>{{ label }}</label>
        <b-input-group>
            <b-form-select
                :options="options"
                @change="onChange($event)"
                :value="data.params[paramName]"
            >
            </b-form-select>

            <b-input-group-append>
                <b-input-group-text>
                    <b-form-checkbox
                        class="mr-n2"
                        v-b-popover.hover.top="'Show'"
                        value="false"
                        disabled
                        switch
                    >
                    </b-form-checkbox>
                </b-input-group-text>
                <b-input-group-text>
                    <b-form-checkbox
                        class="mr-n2"
                        v-b-popover.hover.top="'Edit'"
                        value="false"
                        disabled
                        switch
                    >
                    </b-form-checkbox>
                </b-input-group-text>
            </b-input-group-append>
        </b-input-group>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        className: {
            type: String,
            required: true,
        },
        paramName: {
            type: String,
            required: true,
        },
    },
    methods: {
        onChange(newVal) {
            this.data.params[this.paramName] = newVal

            this.$emit('redrawnetwork')
        },
    },
    computed: {
        options() {
            const units = this.data.game.simulation.template.plant.units
            let dropDownUnits = []

            for (let unitName in units) {
                const unit = units[unitName]
                if (unit.class === this.className) {
                    dropDownUnits.push({ value: unitName, text: unitName })
                }
            }

            dropDownUnits.push({ value: null, text: 'N/A', disabled: true })

            return dropDownUnits
        },
    },
}
</script>
