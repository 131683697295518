import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { routes } from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, from, next) => {
    const authRequired = to.matched.some((record) => record.meta.authRequired)
    const onlyWhenLoggedOut = to.matched.some(
        (record) => record.meta.onlyWhenLoggedOut
    )

    if (authRequired && !store.getters.userLoggedIn) {
        return next({
            name: 'login',
            query: {
                redirect: to.fullPath,
            },
        })
    }

    if (onlyWhenLoggedOut && store.getters.userLoggedIn) {
        return to.query.redirect
            ? next({ path: to.query.redirect })
            : next({ name: 'game' })
    }

    next()
})

export default router
