<template>
    <div v-if="showContract" class="mt-2">
        <label>Contract:</label>

        <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
                <b-icon icon="file-earmark-medical-fill" aria-hidden="true">
                </b-icon>
            </b-input-group-prepend>

            <b-form-select
                class="title bg-light"
                v-model="data.params.contract_active"
                :disabled="!chooseContractAllowed"
                @change="updateContractTerms()"
            >
                <b-form-select-option
                    v-for="(terms, name) in data.params.contracts"
                    :key="name"
                    :value="name"
                >
                    {{ name }}
                </b-form-select-option>
            </b-form-select>

            <b-input-group-append>
                <b-input-group-text v-if="!isGamePlay">
                    <b-form-checkbox
                        class="mr-n2"
                        v-model="data.layout.params.contract.show"
                        v-b-popover.hover.top="'Show'"
                        @change="onContractShowToggle"
                        switch
                    >
                    </b-form-checkbox>
                </b-input-group-text>

                <b-input-group-text v-if="!isGamePlay">
                    <b-form-checkbox
                        class="mr-n2"
                        v-model="data.layout.params.contract.edit"
                        v-b-popover.hover.top="'Change'"
                        @change="onContractEditToggle"
                        switch
                    >
                    </b-form-checkbox>
                </b-input-group-text>

                <b-button
                    v-if="isGamePlay"
                    variant="success"
                    :disabled="!chooseContractAllowed || waitingSubmitResponse"
                    @click="$emit('submitContractChange')"
                >
                    Submit
                </b-button>
            </b-input-group-append>
        </b-input-group>
        <div>
            <b-input-group
                class="mt-2"
                v-b-popover.hover.top="
                    'Asked lead time in hours (customer pays full price)'
                "
                v-if="!isGamePlay || data.layout.params.lead_time.show"
            >
                <b-input-group-prepend is-text>
                    <b-icon
                        icon="arrow-right-circle"
                        aria-hidden="true"
                    ></b-icon>
                </b-input-group-prepend>

                <b-form-input
                    v-model="getActiveContractTerms().lead_time"
                    :disabled="isGamePlay"
                    :number="true"
                    type="number"
                    min="1"
                >
                </b-form-input>

                <show-edit
                    :data="data"
                    paramName="lead_time"
                    disallowAllEdit="true"
                >
                </show-edit>
            </b-input-group>
            <b-input-group
                class="mt-2"
                v-b-popover.hover.top="
                    'Maximum lead time in hours (if exceeded customer pays nothing)'
                "
                v-if="!isGamePlay || data.layout.params.lead_time_max.show"
            >
                <b-input-group-prepend is-text>
                    <b-icon icon="arrow-right-circle-fill" aria-hidden="true">
                    </b-icon>
                </b-input-group-prepend>

                <b-form-input
                    v-model="getActiveContractTerms().lead_time_max"
                    :disabled="isGamePlay"
                    :number="true"
                    type="number"
                    min="1"
                >
                </b-form-input>
                <show-edit
                    :data="data"
                    paramName="lead_time_max"
                    disallowAllEdit="true"
                >
                </show-edit>
            </b-input-group>
            <b-input-group
                class="mt-2"
                v-b-popover.hover.top="'Price per box'"
                v-if="!isGamePlay || data.layout.params.batch_price.show"
            >
                <b-input-group-prepend is-text>
                    <span class="mx-1">$</span>
                </b-input-group-prepend>

                <b-form-input
                    v-model="getActiveContractTerms().batch_price"
                    :disabled="isGamePlay"
                    :number="true"
                    type="number"
                    min="0"
                >
                </b-form-input>
                <show-edit
                    :data="data"
                    paramName="batch_price"
                    disallowAllEdit="true"
                >
                </show-edit>
            </b-input-group>
            <b-input-group
                class="mt-2"
                v-b-popover.hover.top="'Delivery costs per order'"
                v-if="!isGamePlay || data.layout.params.fix_price.show"
            >
                <b-input-group-prepend is-text>
                    <span>+$</span>
                </b-input-group-prepend>

                <b-form-input
                    v-model="getActiveContractTerms().fix_price"
                    :disabled="isGamePlay"
                    :number="true"
                    type="number"
                    min="0"
                >
                </b-form-input>
                <show-edit
                    :data="data"
                    paramName="fix_price"
                    disallowAllEdit="true"
                >
                </show-edit>
            </b-input-group>
            <b-input-group
                class="mt-2"
                v-b-popover.hover.top="'Number of items per box'"
                v-if="!isGamePlay || data.layout.params.batch_size.show"
            >
                <b-input-group-prepend is-text>
                    <b-icon icon="grid" aria-hidden="true"></b-icon>
                </b-input-group-prepend>

                <b-form-input
                    v-model="getActiveContractTerms().batch_size"
                    :disabled="isGamePlay"
                    :number="true"
                    type="number"
                    min="1"
                >
                </b-form-input>
                <show-edit
                    :data="data"
                    paramName="batch_size"
                    disallowAllEdit="true"
                >
                </show-edit>
            </b-input-group>
        </div>
        <div class="row mt-2" v-if="!isGamePlay">
            <div class="col">
                <b-button
                    variant="outline-success"
                    v-b-modal.save-contract-as-modal
                    size="sm"
                    block
                >
                    Create new contract
                </b-button>
            </div>
            <div class="col">
                <b-button
                    variant="outline-danger"
                    size="sm"
                    :disabled="disableContractDelete()"
                    @click="onContractDelete"
                    block
                >
                    Delete
                </b-button>
            </div>
        </div>

        <b-modal
            ref="saveContractAsModal"
            id="save-contract-as-modal"
            title="Create a copy of a contract"
            @ok="saveContractAs"
            centered
        >
            <b-form-input
                v-model="contract.newName"
                placeholder="Enter a contract name..."
            >
            </b-form-input>
        </b-modal>
    </div>
</template>
<script>
import lodash from 'lodash'
import Vue from 'vue'
import ShowEdit from './ShowEdit.vue'

export default {
    components: { ShowEdit },
    props: {
        data: {
            type: Object,
            required: true,
        },
        waitingSubmitResponse: {
            type: Boolean,
            required: true,
        },
        contract: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isGamePlay() {
            return this.$route.name == 'gamePlay'
        },
        showContract() {
            return this.data.layout.params.contract.show || !this.isGamePlay
        },
        chooseContractAllowed: function () {
            if (!this.isGamePlay) {
                return true
            }

            return this.data.layout.params.contract.edit
        },
    },
    methods: {
        disableContractDelete() {
            return Object.keys(this.data.params.contracts).length <= 1
        },
        getActiveContractTerms() {
            return this.data.params.contracts[this.data.params.contract_active]
        },
        saveContractAs() {
            this.$refs.saveContractAsModal.hide()

            if (!this.contract.newName) {
                return
            }

            if (this.contract.newName in this.data.params.contracts) {
                this.$bvToast.toast(
                    `The contract "${this.contract.newName}" already exists!`,
                    {
                        title: 'Error',
                        toaster: 'b-toaster-top-center',
                        variant: 'danger',
                        solid: true,
                    }
                )

                return
            }

            Vue.set(
                this.data.params.contracts,
                this.contract.newName,
                lodash.cloneDeep(this.getActiveContractTerms())
            )
            this.data.params.contract_active = this.contract.newName
        },
        onContractDelete() {
            let selected = this.data.params.contract_active
            this.$bvModal
                .msgBoxConfirm(`Delete "${selected}" contract. Are you sure?`, {
                    title: 'Delete Contract',
                    centered: true,
                })
                .then(
                    ((success) => {
                        if (success) {
                            delete this.data.params.contracts[selected]
                            this.data.params.contract_active = Object.keys(
                                this.data.params.contracts
                            )[0]
                        }
                    }).bind(this)
                )
        },
        onContractShowToggle(show) {
            if (!show) {
                this.data.layout.params.contract.edit = false
                this.$forceUpdate()
            }
        },
        onContractEditToggle(edit) {
            if (edit) {
                this.data.layout.params.contract.show = true
                this.$forceUpdate()
            }
        },
        updateContractTerms() {
            this.contract.showTermsKey += 1
        },
    },
    created() {
        this.data.layout.params.lead_time ??= { show: true, edit: false }
        this.data.layout.params.lead_time_max ??= { show: true, edit: false }
        this.data.layout.params.batch_price ??= { show: true, edit: false }
        this.data.layout.params.fix_price ??= { show: true, edit: false }
        this.data.layout.params.batch_size ??= { show: true, edit: false }
    },
}
</script>
