import Vue from 'vue'
import { Network } from 'vue-vis-network'

import Factory from './Designer/Factory'
import GameInfo from './Designer/Game/GameInfo'
import CustomerParams from './Designer/Units/CustomerParams'
import ShipmentParams from './Designer/Units/ShipmentParams'
import ShipmentPlots from './Designer/Units/ShipmentPlots'
import MatcherParams from './Designer/Units/MatcherParams'
import MatcherPlots from './Designer/Units/MatcherPlots'
import WarehouseParams from './Designer/Units/WarehouseParams'
import WarehousePlots from './Designer/Units/WarehousePlots'
import WorkshopParams from './Designer/Units/WorkshopParams'
import WorkshopPlots from './Designer/Units/WorkshopPlots'
import SupplierParams from './Designer/Units/SupplierParams'
import SupplierPlots from './Designer/Units/SupplierPlots'
import OfficeParams from './Designer/Units/OfficeParams'
import OfficePlots from './Designer/Units/OfficePlots'

Vue.component('network', Network)
Vue.component('factory', Factory)
Vue.component('game-info', GameInfo)
Vue.component('customer-params', CustomerParams)
Vue.component('shipment-params', ShipmentParams)
Vue.component('shipment-plots', ShipmentPlots)
Vue.component('matcher-params', MatcherParams)
Vue.component('matcher-plots', MatcherPlots)
Vue.component('warehouse-params', WarehouseParams)
Vue.component('warehouse-plots', WarehousePlots)
Vue.component('workshop-params', WorkshopParams)
Vue.component('workshop-plots', WorkshopPlots)
Vue.component('supplier-params', SupplierParams)
Vue.component('supplier-plots', SupplierPlots)
Vue.component('office-params', OfficeParams)
Vue.component('office-plots', OfficePlots)
