import lodash from 'lodash'

var plotDataMixin = {
    data: function () {
        return {
            recentDaysNumber: 30,
        }
    },
    methods: {
        getRecentStats: function (days = null) {
            if (!this.data) {
                return
            }

            var stats = this.data.stats
            var labels = lodash.range(1, stats.length + 1)

            const num = days ?? this.recentDaysNumber

            if (num) {
                stats = stats.slice(-num)
                labels = labels.slice(-num)
            }

            return { stats: stats, labels: labels }
        },
    },
}

export default {
    plotDataMixin,
}
