<template>
    <div v-if="chartData" class="container">
        <line-chart
            :width="700"
            :height="450"
            v-bind:chartData="chartData"
            :options="options"
        />
    </div>
</template>
<script>
import LineChart from './Plots/LineChart.vue'
import mixins from './Plots/mixins'

export default {
    components: { LineChart },
    mixins: [mixins.plotDataMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        maintainAspectRatio: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            chartData: null,
            options: {
                title: {
                    display: true,
                    text: 'Inventory at the end of the day',
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                            gridLines: {
                                display: true,
                            },
                            scaleLabel: {
                                display: true,
                                fontStyle: 'italic',
                                labelString: 'Inventory [items]',
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            scaleLabel: {
                                display: true,
                                fontStyle: 'italic',
                                labelString: 'Day',
                            },
                        },
                    ],
                },
                legend: {
                    display: true,
                },
                responsive: true,
                maintainAspectRatio: this.maintainAspectRatio,
            },
        }
    },
    methods: {
        initChartData() {
            let { stats, labels } = this.getRecentStats()
            this.chartData = {
                labels: labels,

                datasets: [
                    {
                        label: 'On-hand inventory',
                        data: stats.map((s) => s['on hand inventory']),
                        backgroundColor: 'rgb(0, 128, 128, 0.5)',
                        fill: true,
                        lineTension: 0,
                        pointRadius: 0,
                        steppedLine: 'middle',
                    },
                    {
                        label: 'System inventory (on-hand inventory + pipeline inventory - backlog)',
                        data: stats.map((s) => s['available stock']),
                        borderColor: 'rgb(0, 0, 255, 0.5)',
                        fill: false,
                        lineTension: 0,
                        pointRadius: 0,
                        steppedLine: 'middle',
                    },

                    {
                        label: 'Reorder point',
                        data: stats.map((s) => s['reorder point']),
                        borderColor: 'salmon',
                        fill: false,
                        pointRadius: 0,
                        steppedLine: 'middle',
                    },
                ],
            }
        },
    },
    mounted() {
        this.initChartData()
    },
    watch: {
        data: function () {
            if (!!this.data) {
                this.initChartData()
            }
        },
    },
}
</script>
