export function toFormattedNumber(number) {
    if (typeof number !== 'number') {
        return ''
    }
    if (isNaN(number)) {
        return ''
    }
    return number.toLocaleString('en-us')
}

export function toFormattedCurrency(number) {
    return toFormattedNumber(number) + ' $'
}
