<template>
    <div class="card shadow-sm mt-3">
        <div class="card-header">Pipeline</div>
        <div class="card-body text-muted font-weight-light">
            <label>Pipeline:</label>

            <b-input-group>
                <b-input-group-prepend is-text>
                    <b-icon icon="node-plus" aria-hidden="true"></b-icon>
                </b-input-group-prepend>

                <b-form-select v-model="pipelineName" :options="pipelines">
                </b-form-select>
            </b-input-group>
            <pipeline
                :game="game"
                :pipelineName="pipelineName"
                @redrawnetwork="$emit('redrawnetwork')"
            >
            </pipeline>
        </div>
    </div>
</template>
<script>
import Pipeline from './Pipeline.vue'
export default {
    components: { Pipeline },
    props: {
        game: {
            type: Object,
            required: true,
        },
    },
    created() {
        this.pipelineName = this.pipelines[0]
    },
    computed: {
        pipelines() {
            return Object.keys(this.game.simulation.template.plant.pipeline)
        },
    },
}
</script>
