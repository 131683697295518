<template>
    <div class="d-flex flex-column min-vh-100">
        <b-navbar toggleable="lg" type="dark" variant="info">
            <b-navbar-brand v-b-toggle.sidebar>INSYSTED</b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item-dropdown right>
                        <template #button-content>
                            <b-icon icon="person-fill" class="mr-2"></b-icon>
                            Menu
                        </template>
                        <b-dropdown-item to="/game">
                            <b-icon icon="controller"></b-icon>
                            Play
                        </b-dropdown-item>
                        <b-dropdown-item to="/design" v-if="isDesigner">
                            <b-icon icon="command"></b-icon>
                            Design
                        </b-dropdown-item>
                        <b-dropdown-item to="/account">
                            <b-icon icon="file-earmark-person"></b-icon>
                            Account
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="logout">
                            <b-icon icon="power" aria-hidden="true"></b-icon>
                            Logout
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
        <b-sidebar id="sidebar" backdrop-variant="dark" backdrop shadow>
            <nav class="mb-3">
                <b-nav vertical>
                    <b-nav-item to="/game">
                        <b-icon icon="controller"></b-icon>
                        Play
                    </b-nav-item>
                    <b-nav-item v-if="isDesigner" to="/design">
                        <b-icon icon="command"></b-icon>
                        Design
                    </b-nav-item>
                    <b-nav-item to="/account">
                        <b-icon icon="file-earmark-person"></b-icon>
                        Account
                    </b-nav-item>
                </b-nav>
            </nav>
        </b-sidebar>

        <div class="flex-fill">
            <router-view />
        </div>

        <b-modal id="icons-modal" title="Icons" hide-footer>
            <ul>
                <li>
                    "Customer" by
                    <a href="https://www.flaticon.com/authors/freepik"
                        >Freepik</a
                    >
                    from
                    <a
                        href="https://www.flaticon.com/free-icon/employee_3789820"
                        >flaticon.com</a
                    >
                </li>
                <li>
                    "Inv. Matcher" by
                    <a href="https://www.flaticon.com/authors/freepik"
                        >Freepik</a
                    >
                    from
                    <a href="https://www.flaticon.com/free-icon/laptop_1585198"
                        >flaticon.com</a
                    >
                </li>
                <li>
                    "Sales Office" by
                    <a href="https://www.flaticon.com/authors/freepik"
                        >Freepik</a
                    >
                    from
                    <a
                        href="https://www.flaticon.com/free-icon/office-building_2830532"
                        >flaticon.com</a
                    >
                </li>
                <li>
                    "Shipment" by
                    <a href="https://www.flaticon.com/authors/freepik"
                        >Freepik</a
                    >
                    from
                    <a href="https://www.flaticon.com/free-icon/export_5827605?"
                        >flaticon.com</a
                    >
                </li>
                <li>
                    "Supplier" by
                    <a href="https://www.flaticon.com/authors/freepik"
                        >Freepik</a
                    >
                    from
                    <a
                        href="https://www.flaticon.com/free-icon/delivery-truck_4047377"
                        >flaticon.com</a
                    >
                </li>
                <li>
                    "Warehuse" by
                    <a href="https://www.flaticon.com/authors/freepik"
                        >Freepik</a
                    >
                    from
                    <a
                        href="https://www.flaticon.com/free-icon/warehouse_1839177"
                        >flaticon.com</a
                    >
                </li>
                <li>
                    "Workshop" by
                    <a href="https://www.flaticon.com/authors/freepik"
                        >Freepik</a
                    >
                    from
                    <a
                        href="https://www.flaticon.com/free-icon/development_1857069"
                        >flaticon.com</a
                    >
                </li>
                <li>
                    facicon.ic by
                    <a href="https://www.flaticon.com/authors/freepik"
                        >Freepik</a
                    >
                    from
                    <a
                        href="https://www.flaticon.com/free-icon/settings_3790130"
                        >flaticon.com</a
                    >
                </li>
            </ul>
        </b-modal>
        <footer class="footer">
            <div class="text-muted row w-100">
                <span class="text-center col">
                    <a href="https://gitlab.com/insysted/insysted">About</a>
                </span>
                <span class="text-center col text-monospace font-weight-bolder"
                    >INSYSTED</span
                >
                <span class="text-center col">
                    <b-button variant="link" v-b-modal.icons-modal
                        >Icons</b-button
                    >
                </span>
            </div>
        </footer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            currentYear: new Date().getFullYear(),
        }
    },
    computed: {
        isDesigner() {
            return this.$store.getters.userHasRole('DESIGNER')
        },
    },
    methods: {
        logout() {
            this.$store.commit('logout')
            this.$router.push('/login')
        },
    },
}
</script>
<style>
.footer {
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: #f5f5f5;
}
</style>
