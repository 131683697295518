<template>
    <b-input-group class="mt-2" v-if="show">
        <b-input-group-prepend is-text>
            <b-icon v-if="icon.length > 1" :icon="icon" aria-hidden="true">
            </b-icon>
            <span class="mx-1" v-else>{{ icon }}</span>
        </b-input-group-prepend>

        <b-form-input
            v-model="data.params[paramName]"
            :disabled="isGamePlay"
            :number="true"
            type="number"
            min="0"
        >
        </b-form-input>
        <show-edit :data="data" :paramName="paramName" :disallowAllEdit="true">
        </show-edit>
    </b-input-group>
</template>

<script>
import ShowEdit from './ShowEdit.vue'

export default {
    components: { ShowEdit },
    props: {
        icon: {
            type: String,
            required: true,
        },
        paramName: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isGamePlay: function () {
            return this.$route.name == 'gamePlay'
        },
        show() {
            return (
                this.data.layout.params[this.paramName].show || !this.isGamePlay
            )
        },
    },
}
</script>
