import axios from 'axios'
import moment from 'moment'
import store from '@/store'
import router from '@/router'
import { encodeQueryData } from './api.utils'

const URL = {
    refreshToken: '/api/auth/refresh-access-token/',
    login: '/api/auth/login/',
    logout: '/api/auth/logout/',
    user: '/api/user/',
    updateUser: '/api/user/update/',
    register: '/api/user/register/',
    confirmAccount: '/api/user/confirm/',
    game: '/api/game/',
    startGame: '/api/game/start/',
    asyncGame: '/api/game/async/',
    restartGame: '/api/game/restart/',
    abortGame: '/api/game/abort/',
    controlGameFlow: '/api/game/flow/',
    team: '/api/game/team/',
    joinTeam: '/api/user/team/join/',
    joinAsync: '/api/game/joinAsync/',
    template: '/api/game/template/',
    applyGameAction: '/api/game/action/',
}

function refreshToken() {
    return axios
        .get(URL.refreshToken, {
            headers: {
                Authorization: `Bearer ${store.state.auth.tokens.refresh}`,
            },
        })
        .then(
            (response) => {
                console.log('user got new access token')
                store.commit('login', {
                    accessToken: response.data.auth.tokens.access,
                    userRoles: response.data.auth.user.roles,
                    userId: store.state.auth.user.id,
                })

                return Promise.resolve(response.data.auth.tokens.access)
            },
            (error) => {
                console.log('user failed to refresh access token')
                store.commit('logout')
                router.push('/login')

                return Promise.reject(error)
            }
        )
}

const client = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
})

client.interceptors.request.use((config) => {
    const token = store.state.auth.tokens.access

    if (!!token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config
})

client.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response.status !== 401) {
            if (error.response.status === 408) {
                console.log(`timeout on url ${error.config.url}`)
            }

            return Promise.reject(error)
        }

        if (error.config.url.includes(URL.refreshToken)) {
            console.log('failed to refresh access token, give up trying...')
            store.commit('logout')
            router.push('/login')

            return Promise.reject(error)
        }

        if (!store.state.auth.tokens.refresh) {
            console.log('user does not have a refresh token')

            return Promise.reject(error)
        }

        return refreshToken().then(
            (token) => {
                const config = error.config
                config.headers.Authorization = `Bearer ${token}`

                return axios
                    .request(config)
                    .then((response) => {
                        return response
                    })
                    .catch((error) => {
                        return Promise.reject(error)
                    })
            },
            (error) => {
                return Promise.reject(error)
            }
        )
    }
)

function login(user) {
    return client.post(URL.login, {
        identity: user.email,
        password: user.password,
    })
}

function logout() {
    return client.get(URL.logout)
}

function register(user) {
    return client.post(URL.register, {
        name: user.name,
        email: user.email,
        password: user.password,
    })
}

function confirmAccount(token) {
    return client.get(`${URL.confirmAccount}${token}/`)
}

function getGame(args = {}) {
    var url = !args.id ? URL.game : `${URL.game}${args.id}/`
    url +=
        '?' +
        encodeQueryData({
            title: args.title,
            offset: args.offset,
            limit: args.limit,
        })

    return client.get(url)
}

function saveGame(gameSchema, gameId = null) {
    const url = !gameId
        ? `${URL.game}${gameSchema._bracketing_behavior}/`
        : `${URL.game}${gameSchema._bracketing_behavior}/${gameId}/`
    delete gameSchema._bracketing_behavior
    return client.post(url, gameSchema)
}

function deleteGame(gameId) {
    return client.delete(`${URL.game}${gameId}/`)
}

function startGame(gameId, startDate = null) {
    var payload = { game_id: gameId }

    if (!!startDate) {
        payload.start_at = moment.utc(startDate).format()
    }

    return client.post(URL.startGame, payload)
}

function asyncGame(gameId) {
    return client.post(URL.asyncGame, { game_id: gameId })
}

function restartAsyncGame(teamId) {
    return client.post(URL.restartGame, { team_id: teamId })
}

function abortGame(gameId) {
    return client.post(URL.abortGame, { game_id: gameId })
}

function toggleSuspendGame(gameId) {
    return client.post(`${URL.controlGameFlow}suspend/`, { game_id: gameId })
}

function toggleFastForwardGame(gameId) {
    return client.post(`${URL.controlGameFlow}fastforward/`, {
        game_id: gameId,
    })
}

function resetGameClock(gameId, day = 0) {
    return client.post(`${URL.controlGameFlow}reset/`, {
        game_id: gameId,
        reset_day: day,
    })
}

function getTeam(args = {}) {
    var url = !args.id ? URL.team : `${URL.team}${args.id}/`
    url +=
        '?' +
        encodeQueryData({
            title: args.title,
            offset: args.offset,
            limit: args.limit,
        })

    return client.get(url)
}

function saveTeam(gameId, teamName, bracket) {
    return client.post(URL.team, {
        game_id: gameId,
        team: { name: teamName, bracket: bracket },
    })
}

function deleteTeam(teamId) {
    return client.delete(`${URL.team}${teamId}/`)
}

function joinTeam(id) {
    return client.get(`${URL.joinTeam}${id}/`)
}

function joinAsync(id) {
    return client.get(`${URL.joinAsync}${id}/`)
}

function getTemplate(args = {}) {
    var url = !args.id ? URL.template : `${URL.template}${args.id}/`
    url +=
        '?' +
        encodeQueryData({
            title: args.title,
            offset: args.offset,
            limit: args.limit,
            public: args.showPublic,
        })

    return client.get(url)
}

function saveTemplate(gameId, isPublic = false) {
    return client.post(URL.template, { game_id: gameId, public: isPublic })
}

function updateTemplate(tempId, simulation) {
    return client.post(`${URL.template}${tempId}/`, { simulation: simulation })
}

function deleteTemplate(tempId) {
    return client.delete(`${URL.template}${tempId}/`)
}

function gameLink(gameId) {
    return `${window.location.origin}/api/auth/lti/${gameId}/`
}

function gameLinkAsync(gameId) {
    return `${window.location.origin}/api/auth/lti/async/${gameId}/`
}

function gameLinkAsyncIndv(gameId) {
    return `${window.location.origin}/async/join/${gameId}/`
}

function applyGameAction(teamId, unitId, param, value) {
    return client.post(URL.applyGameAction, {
        team_id: teamId,
        unit_id: unitId,
        param: param,
        value: value.toString(),
    })
}

function changeQueuePolicy(teamId, unitId, policy) {
    return applyGameAction(teamId, unitId, 'queue_policy', policy)
}

function changeWarehouseReorderPoint(teamId, unitId, reorderPoint) {
    return applyGameAction(
        teamId,
        unitId,
        'warehouse_reorder_point',
        reorderPoint
    )
}

function changeWarehouseOrderSize(teamId, unitId, size) {
    return applyGameAction(teamId, unitId, 'warehouse_order_size', size)
}

function purchaseWorkshopMachines(teamId, unitId, quantity) {
    return applyGameAction(
        teamId,
        unitId,
        'workshop_purchase_machine',
        quantity
    )
}

function retireWorkshopMachines(teamId, unitId, quantity) {
    return applyGameAction(teamId, unitId, 'workshop_retire_machine', quantity)
}

function changeActiveContract(teamId, unitId, contract) {
    return applyGameAction(teamId, unitId, 'contract', contract)
}

function changeLotSize(teamId, unitId, size) {
    return applyGameAction(teamId, unitId, 'lot_size', size)
}

function getUser(id = null) {
    var url = !id ? URL.user : `${URL.user}${id}/`

    return client.get(url)
}

function updateUser(args) {
    if (!(args ?? {}).name) {
        throw 'User name not provided!'
    }

    return client.post(URL.updateUser, args)
}

export default {
    client,
    login,
    logout,
    register,
    confirmAccount,
    joinTeam,
    joinAsync,
    getGame,
    saveGame,
    deleteGame,
    startGame,
    asyncGame,
    restartAsyncGame,
    abortGame,
    toggleSuspendGame,
    toggleFastForwardGame,
    resetGameClock,
    getTeam,
    saveTeam,
    deleteTeam,
    getTemplate,
    saveTemplate,
    updateTemplate,
    deleteTemplate,
    gameLink,
    gameLinkAsync,
    gameLinkAsyncIndv,
    changeQueuePolicy,
    changeWarehouseReorderPoint,
    changeWarehouseOrderSize,
    purchaseWorkshopMachines,
    retireWorkshopMachines,
    changeActiveContract,
    changeLotSize,
    getUser,
    updateUser,
}
