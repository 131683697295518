<template>
    <div class="mb-2" v-if="this.$route.name !== 'gamePlay'">
        <label>Name:</label>
        <b-input-group v-b-popover.hover.top="'Change the units name'">
            <b-form-input :value="data.id" @input="onInput" ref="input">
            </b-form-input>

            <b-input-group-append>
                <b-input-group-text>
                    <b-form-checkbox
                        class="mr-n2"
                        v-b-popover.hover.top="'Show'"
                        value="false"
                        disabled
                        switch
                    >
                    </b-form-checkbox>
                </b-input-group-text>
                <b-input-group-text>
                    <b-form-checkbox
                        class="mr-n2"
                        v-b-popover.hover.top="'Edit'"
                        value="false"
                        disabled
                        switch
                    >
                    </b-form-checkbox>
                </b-input-group-text>
            </b-input-group-append>
        </b-input-group>
    </div>
</template>

<script>
import Vue from 'vue'
import { changeValueOfDependents } from './index'

export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    methods: {
        rename(name) {
            this.$refs.input.$el.classList.remove('is-invalid')

            if (name.length === 0) {
                this.$refs.input.$el.classList.add('is-invalid')
            }

            const layout = this.data.game.layout
            const template = this.data.game.simulation.template
            const plant = template.plant
            const units = template.plant.units
            const id = this.data.id

            if (name === id) {
                return
            }

            if (
                (typeof layout[name] !== 'undefined' &&
                    !!layout[name].position) ||
                (typeof units[name] !== 'undefined' && !!units[name].params)
            ) {
                this.$refs.input.$el.classList.add('is-invalid')
                return
            }

            // change own id
            this.data.id = name

            // change the name in layout
            Vue.set(layout, name, layout[id])
            delete layout[id]

            // change the name in units
            Vue.set(units, name, units[id])
            delete units[id]

            // change the name in pipeline
            for (let pipelineName in plant.pipeline) {
                for (let i = 0; i < plant.pipeline[pipelineName].length; i++) {
                    if (plant.pipeline[pipelineName][i].unit === id) {
                        plant.pipeline[pipelineName][i].unit = name
                    }
                }
            }

            changeValueOfDependents(units, this.data.class, id, name)

            this.$emit('redrawnetwork')
        },
        onInput(value) {
            this.rename(value)
        },
    },
}
</script>
