<template>
    <div class="card shadow-sm">
        <div class="card-header">
            Workshop
            <delete-unit
                ref="deleteBtn"
                v-bind:data="data"
                @deletedunit="$emit('deletedunit')"
                @home="$emit('home')"
            >
            </delete-unit>
        </div>
        <div class="card-body small font-weight-light">
            <div v-if="data">
                <name-field
                    :data="data"
                    @redrawnetwork="$emit('redrawnetwork')"
                >
                </name-field>

                <queue-policy
                    :data="data"
                    paramName="queue_policy"
                    :waitingSubmitResponse="waitingSubmitResponse"
                    @contractChange="submitQueuePolicy"
                >
                </queue-policy>

                <div class="mt-2">
                    <label>Setup time per lot in minutes:</label>
                    <b-input-group v-if="!isGamePlay">
                        <b-form-select
                            v-model="data.params.machine.lot_setup_time_type"
                            :options="[
                                { value: 'constant', text: 'Constant' },
                                {
                                    value: 'normal',
                                    text: 'Normal Distribution',
                                },
                            ]"
                        >
                        </b-form-select>
                        <b-input-group-append>
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-b-popover.hover.top="'Show'"
                                    value="false"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-b-popover.hover.top="'Edit'"
                                    value="false"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                </div>
                <div
                    class="mt-2"
                    v-if="
                        data.params.machine.lot_setup_time_type === 'constant'
                    "
                >
                    <b-input-group
                        v-if="
                            data.layout.params.lot_setup_time_constant.show ||
                            !isGamePlay
                        "
                        v-b-popover.hover.top="'Lot setup time in minutes'"
                    >
                        <b-input-group-prepend is-text>
                            <span class="mx-1">
                                <b-icon icon="clock" aria-hidden="true">
                                </b-icon>
                            </span>
                        </b-input-group-prepend>

                        <b-form-input
                            v-model="
                                data.params.machine.lot_setup_time_constant
                            "
                            :disabled="isGamePlay"
                            :number="true"
                            type="number"
                            min="0"
                            step="0.05"
                        >
                        </b-form-input>

                        <b-input-group-append v-if="!isGamePlay">
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-model="
                                        data.layout.params
                                            .lot_setup_time_constant.show
                                    "
                                    v-b-popover.hover.top="'Show'"
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>

                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-model="
                                        data.layout.params
                                            .lot_setup_time_constant.edit
                                    "
                                    v-b-popover.hover.top="'Edit'"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                    <div
                        v-if="
                            !data.layout.params.lot_setup_time_constant.show &&
                            isGamePlay
                        "
                    >
                        <em>
                            One or more parameters have been hidden by the game
                            creator, try deducing these through analysing the
                            simulation units.
                        </em>
                    </div>
                </div>
                <div
                    class="mt-2"
                    v-else-if="
                        data.params.machine.lot_setup_time_type === 'normal'
                    "
                >
                    <b-input-group
                        v-if="
                            data.layout.params.lot_setup_time_avg.show ||
                            !isGamePlay
                        "
                        v-b-popover.hover.top="
                            'The average lot setup time in minutes'
                        "
                    >
                        <b-input-group-prepend is-text>
                            <span class="mx-1">μ</span>
                        </b-input-group-prepend>

                        <b-form-input
                            v-model="data.params.machine.lot_setup_time_avg"
                            :disabled="isGamePlay"
                            :number="true"
                            type="number"
                            min="0"
                            step="0.05"
                        >
                        </b-form-input>

                        <b-input-group-append v-if="!isGamePlay">
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-model="
                                        data.layout.params.lot_setup_time_avg
                                            .show
                                    "
                                    v-b-popover.hover.top="'Show'"
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>

                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-model="
                                        data.layout.params.lot_setup_time_avg
                                            .edit
                                    "
                                    v-b-popover.hover.top="'Edit'"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>

                    <b-input-group
                        v-if="
                            data.layout.params.lot_setup_time_sigma.show ||
                            !isGamePlay
                        "
                        class="mt-2"
                        v-b-popover.hover.top="
                            'The standard deviation of the lot setup time in minutes'
                        "
                    >
                        <b-input-group-prepend is-text>
                            <span class="mx-1">σ</span>
                        </b-input-group-prepend>

                        <b-form-input
                            v-model="data.params.machine.lot_setup_time_sigma"
                            :disabled="isGamePlay"
                            :number="true"
                            type="number"
                            min="0"
                            step="0.05"
                        >
                        </b-form-input>

                        <b-input-group-append v-if="!isGamePlay">
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-model="
                                        data.layout.params.lot_setup_time_sigma
                                            .show
                                    "
                                    v-b-popover.hover.top="'Show'"
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>

                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-model="
                                        data.layout.params.lot_setup_time_sigma
                                            .edit
                                    "
                                    v-b-popover.hover.top="'Edit'"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                    <div
                        v-if="
                            isGamePlay &&
                            (!data.layout.params.lot_setup_time_sigma.show ||
                                !data.layout.params.lot_setup_time_avg.show)
                        "
                    >
                        <em>
                            One or more parameters have been hidden by the game
                            creator, try deducing these through analysing the
                            simulation units.
                        </em>
                    </div>
                </div>

                <div class="mt-2">
                    <label>Item processing time in minutes:</label>
                    <b-input-group v-if="!isGamePlay">
                        <b-form-select
                            v-model="
                                data.params.machine.item_processing_time_type
                            "
                            :options="[
                                { value: 'constant', text: 'Constant' },
                                {
                                    value: 'normal',
                                    text: 'Normal Distribution',
                                },
                            ]"
                        >
                        </b-form-select>
                        <b-input-group-append>
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-b-popover.hover.top="'Show'"
                                    value="false"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-b-popover.hover.top="'Edit'"
                                    value="false"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                </div>
                <div
                    class="mt-2"
                    v-if="
                        data.params.machine.item_processing_time_type ===
                        'constant'
                    "
                >
                    <b-input-group
                        v-if="
                            data.layout.params.item_processing_time_constant
                                .show || !isGamePlay
                        "
                        v-b-popover.hover.top="
                            'The item processing time in minutes'
                        "
                    >
                        <b-input-group-prepend is-text>
                            <span class="mx-1">
                                <b-icon icon="clock" aria-hidden="true">
                                </b-icon>
                            </span>
                        </b-input-group-prepend>

                        <b-form-input
                            v-model="
                                data.params.machine
                                    .item_processing_time_constant
                            "
                            :disabled="isGamePlay"
                            :number="true"
                            type="number"
                            min="0"
                            step="0.05"
                        >
                        </b-form-input>

                        <b-input-group-append v-if="!isGamePlay">
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-model="
                                        data.layout.params
                                            .item_processing_time_constant.show
                                    "
                                    v-b-popover.hover.top="'Show'"
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>

                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-model="
                                        data.layout.params
                                            .item_processing_time_constant.edit
                                    "
                                    v-b-popover.hover.top="'Edit'"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                    <div
                        v-if="
                            !data.layout.params.item_processing_time_constant
                                .show && isGamePlay
                        "
                    >
                        <em>
                            One or more parameters have been hidden by the game
                            creator, try deducing these through analysing the
                            simulation units.
                        </em>
                    </div>
                </div>
                <div
                    class="mt-2"
                    v-else-if="
                        data.params.machine.item_processing_time_type ===
                        'normal'
                    "
                >
                    <b-input-group
                        v-if="
                            data.layout.params.item_processing_time_avg.show ||
                            !isGamePlay
                        "
                        v-b-popover.hover.top="
                            'The average item processing time in minutes'
                        "
                    >
                        <b-input-group-prepend is-text>
                            <span class="mx-1">μ</span>
                        </b-input-group-prepend>

                        <b-form-input
                            v-model="
                                data.params.machine.item_processing_time_avg
                            "
                            :disabled="isGamePlay"
                            :number="true"
                            type="number"
                            min="0"
                            step="0.05"
                        >
                        </b-form-input>

                        <b-input-group-append v-if="!isGamePlay">
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-model="
                                        data.layout.params
                                            .item_processing_time_avg.show
                                    "
                                    v-b-popover.hover.top="'Show'"
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>

                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-model="
                                        data.layout.params
                                            .item_processing_time_avg.edit
                                    "
                                    v-b-popover.hover.top="'Edit'"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                    <b-input-group
                        v-if="
                            data.layout.params.item_processing_time_sigma
                                .show || !isGamePlay
                        "
                        class="mt-2"
                        v-b-popover.hover.top="
                            'The standard deviation of the item processing time in minutes'
                        "
                    >
                        <b-input-group-prepend is-text>
                            <span class="mx-1">σ</span>
                        </b-input-group-prepend>

                        <b-form-input
                            v-model="
                                data.params.machine.item_processing_time_sigma
                            "
                            :disabled="isGamePlay"
                            :number="true"
                            type="number"
                            min="0"
                            step="0.05"
                        >
                        </b-form-input>

                        <b-input-group-append v-if="!isGamePlay">
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-model="
                                        data.layout.params
                                            .item_processing_time_sigma.show
                                    "
                                    v-b-popover.hover.top="'Show'"
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>

                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-model="
                                        data.layout.params
                                            .item_processing_time_sigma.edit
                                    "
                                    v-b-popover.hover.top="'Edit'"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                    <div
                        v-if="
                            isGamePlay &&
                            (!data.layout.params.item_processing_time_sigma
                                .show ||
                                !data.layout.params.item_processing_time_avg
                                    .show)
                        "
                    >
                        <em>
                            One or more parameters have been hidden by the game
                            creator, try deducing these through analysing the
                            simulation units.
                        </em>
                    </div>
                </div>

                <div class="mt-2" v-if="showBreakdownAllowed">
                    <label>Machine breakdown probability:</label>

                    <b-input-group
                        v-b-popover.hover.top="
                            'Daily probability of breakdowns'
                        "
                    >
                        <b-input-group-prepend is-text>
                            <b-icon icon="dice2-fill" aria-hidden="true">
                            </b-icon>
                        </b-input-group-prepend>

                        <b-form-input
                            v-model="data.params.machine.breakdown.proba"
                            @change="onBreakdownProbaChange"
                            :disabled="isGamePlay"
                            :number="true"
                            type="number"
                            min="0"
                            step="0.05"
                        >
                        </b-form-input>

                        <b-input-group-append v-if="!isGamePlay">
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-model="data.layout.params.breakdown.show"
                                    v-b-popover.hover.top="'Show'"
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>

                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-model="data.layout.params.breakdown.edit"
                                    v-b-popover.hover.top="'Edit'"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>

                    <div
                        class="mt-2"
                        v-if="data.params.machine.breakdown.proba > 0"
                    >
                        <label>Repair time in minutes:</label>
                        <b-input-group v-if="!isGamePlay">
                            <b-form-select
                                v-model="data.params.machine.breakdown_type"
                                :options="[
                                    { value: 'constant', text: 'Constant' },
                                    {
                                        value: 'normal',
                                        text: 'Normal Distribution',
                                    },
                                ]"
                            >
                            </b-form-select>
                            <b-input-group-append>
                                <b-input-group-text>
                                    <b-form-checkbox
                                        class="mr-n2"
                                        v-b-popover.hover.top="'Show'"
                                        value="false"
                                        disabled
                                        switch
                                    >
                                    </b-form-checkbox>
                                </b-input-group-text>
                                <b-input-group-text>
                                    <b-form-checkbox
                                        class="mr-n2"
                                        v-b-popover.hover.top="'Edit'"
                                        value="false"
                                        disabled
                                        switch
                                    >
                                    </b-form-checkbox>
                                </b-input-group-text>
                            </b-input-group-append>
                        </b-input-group>
                        <div
                            class="mt-2"
                            v-if="
                                data.params.machine.breakdown_type ===
                                'constant'
                            "
                        >
                            <b-input-group
                                v-if="
                                    data.layout.params.breakdown_constant
                                        .show || !isGamePlay
                                "
                                v-b-popover.hover.top="'Repair time in minutes'"
                            >
                                <b-input-group-prepend is-text>
                                    <span class="mx-1">
                                        <b-icon icon="clock" aria-hidden="true">
                                        </b-icon>
                                    </span>
                                </b-input-group-prepend>

                                <b-form-input
                                    v-model="
                                        data.params.machine.breakdown_constant
                                    "
                                    :disabled="isGamePlay"
                                    :number="true"
                                    type="number"
                                    min="0"
                                    step="0.05"
                                >
                                </b-form-input>

                                <b-input-group-append v-if="!isGamePlay">
                                    <b-input-group-text>
                                        <b-form-checkbox
                                            class="mr-n2"
                                            v-model="
                                                data.layout.params
                                                    .breakdown_constant.show
                                            "
                                            v-b-popover.hover.top="'Show'"
                                            switch
                                        >
                                        </b-form-checkbox>
                                    </b-input-group-text>

                                    <b-input-group-text>
                                        <b-form-checkbox
                                            class="mr-n2"
                                            v-model="
                                                data.layout.params
                                                    .breakdown_constant.edit
                                            "
                                            v-b-popover.hover.top="'Edit'"
                                            disabled
                                            switch
                                        >
                                        </b-form-checkbox>
                                    </b-input-group-text>
                                </b-input-group-append>
                            </b-input-group>
                            <div
                                v-if="
                                    !data.layout.params.breakdown_constant
                                        .show && isGamePlay
                                "
                            >
                                <em>
                                    One or more parameters have been hidden by
                                    the game creator, try deducing these through
                                    analysing the simulation units.
                                </em>
                            </div>
                        </div>
                        <div
                            class="mt-2"
                            v-else-if="
                                data.params.machine.breakdown_type === 'normal'
                            "
                        >
                            <b-input-group
                                v-if="
                                    data.layout.params.breakdown_avg.show ||
                                    !isGamePlay
                                "
                                v-b-popover.hover.top="
                                    'The average lot setup time in minutes'
                                "
                            >
                                <b-input-group-prepend is-text>
                                    <span class="mx-1">μ</span>
                                </b-input-group-prepend>

                                <b-form-input
                                    v-model="data.params.machine.breakdown_avg"
                                    :disabled="isGamePlay"
                                    :number="true"
                                    type="number"
                                    min="0"
                                    step="0.05"
                                >
                                </b-form-input>

                                <b-input-group-append v-if="!isGamePlay">
                                    <b-input-group-text>
                                        <b-form-checkbox
                                            class="mr-n2"
                                            v-model="
                                                data.layout.params.breakdown_avg
                                                    .show
                                            "
                                            v-b-popover.hover.top="'Show'"
                                            switch
                                        >
                                        </b-form-checkbox>
                                    </b-input-group-text>

                                    <b-input-group-text>
                                        <b-form-checkbox
                                            class="mr-n2"
                                            v-model="
                                                data.layout.params.breakdown_avg
                                                    .edit
                                            "
                                            v-b-popover.hover.top="'Edit'"
                                            disabled
                                            switch
                                        >
                                        </b-form-checkbox>
                                    </b-input-group-text>
                                </b-input-group-append>
                            </b-input-group>

                            <b-input-group
                                v-if="
                                    data.layout.params.breakdown_sigma.show ||
                                    !isGamePlay
                                "
                                class="mt-2"
                                v-b-popover.hover.top="
                                    'The standard deviation of the lot setup time in minutes'
                                "
                            >
                                <b-input-group-prepend is-text>
                                    <span class="mx-1">σ</span>
                                </b-input-group-prepend>

                                <b-form-input
                                    v-model="
                                        data.params.machine.breakdown_sigma
                                    "
                                    :disabled="isGamePlay"
                                    :number="true"
                                    type="number"
                                    min="0"
                                    step="0.05"
                                >
                                </b-form-input>

                                <b-input-group-append v-if="!isGamePlay">
                                    <b-input-group-text>
                                        <b-form-checkbox
                                            class="mr-n2"
                                            v-model="
                                                data.layout.params
                                                    .breakdown_sigma.show
                                            "
                                            v-b-popover.hover.top="'Show'"
                                            switch
                                        >
                                        </b-form-checkbox>
                                    </b-input-group-text>

                                    <b-input-group-text>
                                        <b-form-checkbox
                                            class="mr-n2"
                                            v-model="
                                                data.layout.params
                                                    .breakdown_sigma.edit
                                            "
                                            v-b-popover.hover.top="'Edit'"
                                            disabled
                                            switch
                                        >
                                        </b-form-checkbox>
                                    </b-input-group-text>
                                </b-input-group-append>
                            </b-input-group>
                            <div
                                v-if="
                                    isGamePlay &&
                                    (!data.layout.params.breakdown_sigma.show ||
                                        !data.layout.params.breakdown_avg.show)
                                "
                            >
                                <em>
                                    One or more parameters have been hidden by
                                    the game creator, try deducing these through
                                    analysing the simulation units.
                                </em>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <label>Number of machines:</label>

                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <b-icon icon="ui-radios-grid" aria-hidden="true">
                            </b-icon>
                        </b-input-group-prepend>

                        <b-form-input
                            v-model="data.params.machine_quantity"
                            :disabled="isGamePlay"
                            :number="true"
                            type="number"
                            min="1"
                        >
                        </b-form-input>

                        <b-input-group-append v-if="!isGamePlay">
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-model="
                                        data.layout.params.machine_quantity.show
                                    "
                                    v-b-popover.hover.top="'Show'"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>

                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-model="
                                        data.layout.params.machine_quantity.edit
                                    "
                                    v-b-popover.hover.top="'Manage'"
                                    @input="onEditMachineQuantityToggle"
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                </div>
                <div v-show="showMachinePricesComputed">
                    <div class="mt-2">
                        <p>Purchase or retire Machines:</p>
                        <label>Machine purchase price:</label>

                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <span class="mx-1">$</span>
                            </b-input-group-prepend>

                            <b-form-input
                                v-model="data.params.machine.purchase_price"
                                :disabled="isGamePlay"
                                :number="true"
                                type="number"
                                min="0"
                            >
                            </b-form-input>
                        </b-input-group>
                    </div>
                    <div class="mt-2" v-if="isGamePlay">
                        <b-input-group
                            v-b-popover.hover.top="
                                'Number of machines to purchase'
                            "
                        >
                            <b-input-group-prepend is-text>
                                <b-icon
                                    icon="node-plus-fill"
                                    aria-hidden="true"
                                >
                                </b-icon>
                            </b-input-group-prepend>

                            <b-form-input
                                v-model="purchaseMachineQuantity"
                                :disabled="!manageMachineQuantity"
                                :number="true"
                                type="number"
                                min="1"
                            >
                            </b-form-input>

                            <b-input-group-append>
                                <b-button
                                    variant="success"
                                    :disabled="
                                        !manageMachineQuantity ||
                                        waitingSubmitResponse
                                    "
                                    @click="submitPurchaseMachines"
                                >
                                    Submit
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                    <div class="mt-2">
                        <label>Machine salvage value:</label>

                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <span class="mx-1">$</span>
                            </b-input-group-prepend>

                            <b-form-input
                                v-model="data.params.machine.retire_price"
                                :disabled="isGamePlay"
                                :number="true"
                                type="number"
                                min="0"
                            >
                            </b-form-input>
                        </b-input-group>
                    </div>
                    <div class="mt-2" v-if="isGamePlay">
                        <b-input-group
                            v-b-popover.hover.top="
                                'Number of machines to retire'
                            "
                        >
                            <b-input-group-prepend is-text>
                                <b-icon
                                    icon="node-minus-fill"
                                    aria-hidden="true"
                                >
                                </b-icon>
                            </b-input-group-prepend>

                            <b-form-input
                                v-model="retireMachineQuantity"
                                :disabled="!manageMachineQuantity"
                                :number="true"
                                type="number"
                                min="1"
                            >
                            </b-form-input>

                            <b-input-group-append>
                                <b-button
                                    variant="success"
                                    :disabled="
                                        !manageMachineQuantity ||
                                        waitingSubmitResponse
                                    "
                                    @click="submitRetireMachines"
                                >
                                    Submit
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from '@/services/api.service'
import { GameStatus } from '@/views'
import NameField from './MetaComponents/NameField.vue'
import DeleteUnit from './MetaComponents/DeleteUnit.vue'
import QueuePolicy from './MetaComponents/QueuePolicy.vue'
import NumberParameter from './MetaComponents/NumberParameter.vue'
import ShowEdit from './MetaComponents/ShowEdit.vue'

export default {
    components: {
        NameField,
        DeleteUnit,
        QueuePolicy,
        NumberParameter,
        ShowEdit,
    },
    props: {
        data: {
            required: true,
        },
    },
    data() {
        return {
            waitingSubmitResponse: false,
            purchaseMachineQuantity: 1,
            retireMachineQuantity: 1,
            showMachinePrices: true,
        }
    },
    computed: {
        isGamePlay: function () {
            return this.$route.name == 'gamePlay'
        },
        isGameStarted: function () {
            return (
                this.data.gameStatus == GameStatus.STARTED ||
                this.data.gameStatus == GameStatus.ASYNC
            )
        },
        manageMachineQuantity: function () {
            return (
                this.data.layout.params.machine_quantity.edit &&
                this.isGameStarted
            )
        },
        showLotSetupTime: function () {
            return !this.isGamePlay
        },
        showUnitProcessingTime: function () {
            return !this.isGamePlay
        },
        showBreakdownAllowed: function () {
            return (
                (this.data.params.machine.breakdown.proba > 0 &&
                    this.data.layout.params.breakdown.show) ||
                !this.isGamePlay
            )
        },
        showMachinePricesComputed() {
            return !this.isGamePlay || this.manageMachineQuantity
        },
    },
    methods: {
        onEditMachineQuantityToggle(edit) {
            this.showMachinePrices = edit
        },
        submitQueuePolicy() {
            this.waitingSubmitResponse = true

            ApiService.changeQueuePolicy(
                this.data.teamId,
                this.data.id,
                this.data.params.queue_policy
            ).then(
                (() => {
                    this.$emit('user-action-submitted')
                    this.waitingSubmitResponse = false
                }).bind(this),
                ((error) => {
                    this.$bvModal
                        .msgBoxOk(error.response.data.message, {
                            title: 'Error',
                            centered: true,
                        })
                        .then(
                            ((success) => {
                                this.waitingSubmitResponse = false
                                this.$emit('user-action-error')
                            }).bind(this)
                        )
                }).bind(this)
            )
        },
        submitPurchaseMachines() {
            let t = this

            this.waitingSubmitResponse = true

            ApiService.purchaseWorkshopMachines(
                this.data.teamId,
                this.data.id,
                this.purchaseMachineQuantity
            ).then(
                (() => {
                    t.data.params.machine_quantity += t.purchaseMachineQuantity

                    this.$emit('user-action-submitted')
                    this.waitingSubmitResponse = false
                }).bind(this),
                ((error) => {
                    this.$bvModal
                        .msgBoxOk(error.response.data.message, {
                            title: 'Error',
                            centered: true,
                        })
                        .then(
                            ((success) => {
                                this.waitingSubmitResponse = false
                                this.$emit('user-action-error')
                            }).bind(this)
                        )
                }).bind(this)
            )
        },
        submitRetireMachines() {
            this.waitingSubmitResponse = true

            let t = this

            ApiService.retireWorkshopMachines(
                this.data.teamId,
                this.data.id,
                this.retireMachineQuantity
            ).then(
                (() => {
                    t.data.params.machine_quantity -= t.retireMachineQuantity

                    this.$emit('user-action-submitted')
                    this.waitingSubmitResponse = false
                }).bind(this),
                ((error) => {
                    this.$bvModal
                        .msgBoxOk(error.response.data.message, {
                            title: 'Error',
                            centered: true,
                        })
                        .then(
                            ((success) => {
                                this.waitingSubmitResponse = false
                                this.$emit('user-action-error')
                            }).bind(this)
                        )
                }).bind(this)
            )
        },
        onBreakdownProbaChange(value) {
            if (value > 0) {
                this.data.layout.params.breakdown.show = true
            }
        },
    },
    created() {
        this.data.params.machine.breakdown ??= {
            proba: 0,
        }

        this.data.layout.params ??= {
            breakdown: { show: true, edit: false },
            breakdown_avg: { show: true, edit: false },
            breakdown_sigma: { show: true, edit: false },
            breakdown_constant: { show: true, edit: false },
            breakdown_type: { show: true, edit: false },
            lot_setup_time_type: { show: true, edit: false },
            lot_setup_time_constant: { show: true, edit: false },
            lot_setup_time_avg: { show: true, edit: false },
            lot_setup_time_sigma: { show: true, edit: false },
            machine_quantity: { show: true, edit: true },
            queue_policy: { show: true, edit: true },
            item_processing_time_avg: { show: true, edit: false },
            item_processing_time_sigma: { show: true, edit: false },
            item_processing_time_constant: { show: true, edit: false },
            item_processing_time_type: { show: true, edit: false },
        }
    },
}
</script>
