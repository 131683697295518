<template>
    <b-input-group-append>
        <b-input-group-text v-if="!isGamePlay">
            <b-form-checkbox
                class="mr-n2"
                v-model="data.layout.params[paramName].show"
                v-b-popover.hover.top="'Show'"
                @change="onShowToggle"
                :disabled="!!disallowHide"
                switch
            >
            </b-form-checkbox>
        </b-input-group-text>

        <b-input-group-text v-if="!isGamePlay">
            <b-form-checkbox
                class="mr-n2"
                v-model="data.layout.params[paramName].edit"
                v-b-popover.hover.top="'Edit'"
                @change="onEditToggle"
                switch
                :disabled="!!disallowAllEdit"
            >
            </b-form-checkbox>
        </b-input-group-text>
    </b-input-group-append>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
        paramName: {
            type: String,
            required: true,
        },
        disallowHide: {
            default: false,
        },
        disallowAllEdit: {
            default: false,
        },
    },
    computed: {
        isGamePlay: function () {
            return this.$route.name == 'gamePlay'
        },
    },
    methods: {
        onShowToggle: function (show) {
            if (!show) {
                this.data.layout.params[this.paramName].edit = false
                this.$forceUpdate()
            }
        },
        onEditToggle: function (edit) {
            if (edit) {
                this.data.layout.params[this.paramName].show = true
                this.$forceUpdate()
            }
        },
    },
}
</script>
