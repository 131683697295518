<template>
    <div class="card shadow-sm">
        <div class="card-header">
            Office
            <delete-unit
                ref="deleteBtn"
                v-bind:data="data"
                @deletedunit="$emit('deletedunit')"
                @home="$emit('home')"
            >
            </delete-unit>
        </div>
        <div class="card-body small font-weight-light">
            <div v-if="data">
                <name-field
                    :data="data"
                    @redrawnetwork="$emit('redrawnetwork')"
                >
                </name-field>

                <queue-policy
                    :data="data"
                    paramName="queue_policy"
                    :waitingSubmitResponse="waitingSubmitResponse"
                    @contractChange="submitQueuePolicy"
                >
                </queue-policy>

                <div v-if="showLotSize" class="mt-2">
                    <label>Lot size in boxes:</label>

                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <b-icon icon="inbox-fill" aria-hidden="true">
                            </b-icon>
                        </b-input-group-prepend>

                        <b-form-input
                            v-model="data.params.lot_size"
                            :disabled="!editLotSizeAllowed"
                            :number="true"
                            type="number"
                            min="1"
                        >
                        </b-form-input>

                        <b-input-group-append>
                            <show-edit :data="data" :paramName="'lot_size'">
                            </show-edit>

                            <b-button
                                v-if="isGamePlay"
                                variant="success"
                                :disabled="
                                    !editLotSizeAllowed || waitingSubmitResponse
                                "
                                @click="submitLotSize"
                            >
                                Submit
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>

                <contract
                    :data="data"
                    :waitingSubmitResponse="waitingSubmitResponse"
                    :contract="contract"
                    @submitContractChange="submitContractChange"
                >
                </contract>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from '@/services/api.service'
import { GameStatus } from '@/views'
import NameField from './MetaComponents/NameField.vue'
import DeleteUnit from './MetaComponents/DeleteUnit.vue'
import QueuePolicy from './MetaComponents/QueuePolicy.vue'
import Contract from './MetaComponents/Contract.vue'
import ShowEdit from './MetaComponents/ShowEdit.vue'

export default {
    components: { NameField, DeleteUnit, QueuePolicy, Contract, ShowEdit },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            waitingSubmitResponse: false,
            contract: {
                showTermsKey: 0,
                newName: null,
                defaultTerms: {
                    lead_time: 2,
                    lead_time_max: 4,
                    batch_price: 100,
                    fix_price: 150,
                    batch_size: 10,
                },
            },
        }
    },
    computed: {
        isGamePlay: function () {
            return this.$route.name == 'gamePlay'
        },
        isGameStarted: function () {
            return (
                this.data.gameStatus == GameStatus.STARTED ||
                this.data.gameStatus == GameStatus.ASYNC
            )
        },
        editLotSizeAllowed: function () {
            if (!this.isGamePlay) {
                return true
            }

            return this.data.layout.params.lot_size.edit && this.isGameStarted
        },
        showLotSize() {
            return this.data.layout.params.lot_size.show || !this.isGamePlay
        },
    },
    methods: {
        submitQueuePolicy() {
            this.waitingSubmitResponse = true

            ApiService.changeQueuePolicy(
                this.data.teamId,
                this.data.id,
                this.data.params.queue_policy
            ).then(
                (() => {
                    this.waitingSubmitResponse = false
                    this.$emit('user-action-submitted')
                }).bind(this),
                ((error) => {
                    this.$bvModal
                        .msgBoxOk(error.response.data.message, {
                            title: 'Error',
                            centered: true,
                        })
                        .then((success) => {
                            this.waitingSubmitResponse = false
                            this.$emit('user-action-error')
                        })
                }).bind(this)
            )
        },
        submitLotSize() {
            this.waitingSubmitResponse = true

            ApiService.changeLotSize(
                this.data.teamId,
                this.data.id,
                this.data.params.lot_size
            ).then(
                (() => {
                    this.waitingSubmitResponse = false
                    this.$emit('user-action-submitted')
                }).bind(this),
                ((error) => {
                    this.$bvModal
                        .msgBoxOk(error.response.data.message, {
                            title: 'Error',
                            centered: true,
                        })
                        .then((success) => {
                            this.waitingSubmitResponse = false
                            this.$emit('user-action-error')
                        })
                }).bind(this)
            )
        },
        submitContractChange() {
            this.waitingSubmitResponse = true

            ApiService.changeActiveContract(
                this.data.teamId,
                this.data.id,
                this.data.params.contract_active
            ).then(
                (() => {
                    this.waitingSubmitResponse = false
                    this.$emit('user-action-submitted')
                }).bind(this),
                ((error) => {
                    this.$bvModal
                        .msgBoxOk(error.response.data.message, {
                            title: 'Error',
                            centered: true,
                        })
                        .then((success) => {
                            this.waitingSubmitResponse = false
                            this.$emit('user-action-error')
                        })
                }).bind(this)
            )
        },
    },
    created() {
        if (!Object.keys(this.data.params.contracts ?? {}).length) {
            this.data.params.contracts = { Default: this.contract.defaultTerms }
            this.data.params.contract_active = 'Default'
        }

        this.data.layout.params ??= {
            queue_policy: { show: true, edit: true },
            lot_size: { show: true, edit: true },
            contract: { show: true, edit: true },
        }
    },
}
</script>
