import AuthLayout from '@/components/Layout/AuthLayout.vue'
import DashboardLayout from '@/components/Layout/DashboardLayout.vue'
import NotFound from '@/views/NotFound.vue'

export const routes = [
    {
        path: '/',
        redirect: 'game',
        component: DashboardLayout,
        meta: {
            authRequired: true,
        },
        children: [
            {
                path: '/game',
                name: 'game',
                component: () => import('@/views/Game.vue'),
            },
            {
                path: '/game/play/:teamId',
                name: 'gamePlay',
                component: () => import('@/views/GamePlay.vue'),
            },
            {
                path: '/game/join/:teamId',
                name: 'gameJoin',
                component: () => import('@/views/GameJoin.vue'),
            },
            {
                path: '/async/join/:gameId',
                name: 'asyncJoin',
                component: () => import('@/views/AsyncJoin.vue'),
            },
            {
                path: '/design',
                name: 'design',
                component: () => import('@/views/Design.vue'),
            },
            {
                path: '/design/game/:gameId?',
                name: 'designGame',
                component: () => import('@/views/DesignGame.vue'),
            },
            {
                path: '/design/template/:tempId',
                name: 'designTemplate',
                component: () => import('@/views/DesignTemplate.vue'),
            },
            {
                path: '/account',
                name: 'account',
                component: () => import('@/views/Account.vue'),
            },
        ],
    },
    {
        path: '/',
        redirect: 'login',
        component: AuthLayout,
        children: [
            {
                path: '/login',
                name: 'login',
                component: () => import('@/views/Login.vue'),
                meta: {
                    onlyWhenLoggedOut: true,
                },
            },
            {
                path: '/register',
                name: 'register',
                component: () => import('@/views/Register.vue'),
                meta: {
                    onlyWhenLoggedOut: true,
                },
            },
            {
                path: '/confirm/account/:token',
                name: 'confirmAccount',
                component: () => import('@/views/ConfirmAccount.vue'),
            },
            {
                path: '*',
                component: NotFound,
            },
        ],
    },
]
