var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card shadow-sm"},[_c('div',{staticClass:"card-header"},[_vm._v(" Supplier "),_c('delete-unit',{ref:"deleteBtn",attrs:{"data":_vm.data},on:{"deletedunit":function($event){return _vm.$emit('deletedunit')},"home":function($event){return _vm.$emit('home')}}})],1),_c('div',{staticClass:"card-body small font-weight-light"},[(_vm.data)?_c('div',[_c('name-field',{attrs:{"data":_vm.data},on:{"redrawnetwork":function($event){return _vm.$emit('redrawnetwork')}}}),(_vm.showLeadTime)?_c('div',{staticClass:"mt-2"},[_c('label',[_vm._v("Delivery time in hours:")]),(!_vm.isGamePlay)?_c('b-input-group',[_c('b-form-select',{attrs:{"options":[
                            { value: 'constant', text: 'Constant' },
                            {
                                value: 'normal',
                                text: 'Normal Distribution',
                            } ]},model:{value:(_vm.data.params.delivery_time_type),callback:function ($$v) {_vm.$set(_vm.data.params, "delivery_time_type", $$v)},expression:"data.params.delivery_time_type"}}),_c('b-input-group-append',[_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Show'),expression:"'Show'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"value":"false","disabled":"","switch":""}})],1),_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"value":"false","disabled":"","switch":""}})],1)],1)],1):_vm._e(),(_vm.data.params.delivery_time_type === 'constant')?_c('div',[_c('number-parameter',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('The delivery time in hours'),expression:"'The delivery time in hours'",modifiers:{"hover":true,"top":true}}],attrs:{"id":"constvalue2","data":_vm.data,"paramName":'delivery_time_constant',"icon":'clock'}}),(!_vm.dTimeShown && _vm.isGamePlay)?_c('div',[_c('em',[_vm._v(" One or more parameters have been hidden by the game creator, try deducing these through analysing the simulation units. ")])]):_vm._e()],1):(_vm.data.params.delivery_time_type === 'normal')?_c('div',[_c('number-parameter',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                            'The average delivery time in hours'
                        ),expression:"\n                            'The average delivery time in hours'\n                        ",modifiers:{"hover":true,"top":true}}],attrs:{"id":"avgvalue2","data":_vm.data,"paramName":'delivery_time_normal_avg',"icon":'μ'}}),_c('number-parameter',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                            'The standard deviation of the delivery time in hours'
                        ),expression:"\n                            'The standard deviation of the delivery time in hours'\n                        ",modifiers:{"hover":true,"top":true}}],attrs:{"id":"sdvalue2","data":_vm.data,"paramName":'delivery_time_normal_sigma',"icon":'σ'}}),(!_vm.dTimeShown && _vm.isGamePlay)?_c('div',[_c('em',[_vm._v(" One or more parameters have been hidden by the game creator, try deducing these through analysing the simulation units. ")])]):_vm._e()],1):_vm._e()],1):_vm._e(),_c('contract',{attrs:{"data":_vm.data,"waitingSubmitResponse":_vm.waitingSubmitResponse,"contract":_vm.contract},on:{"submitContractChange":_vm.submitContractChange}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }