<template>
    <div class="card shadow-sm">
        <div class="card-header">
            Customer
            <delete-unit
                ref="deleteBtn"
                v-bind:data="data"
                @deletedunit="$emit('deletedunit')"
                @home="$emit('home')"
            >
            </delete-unit>
        </div>
        <div class="card-body small font-weight-light">
            <div v-if="data">
                <name-field
                    :data="this.data"
                    @redrawnetwork="$emit('redrawnetwork')"
                >
                </name-field>

                <dependency-dropdown
                    @redrawnetwork="$emit('redrawnetwork')"
                    label="Places orders with:"
                    className="office"
                    paramName="office"
                    :data="data"
                >
                </dependency-dropdown>

                <div class="mt-2">
                    <label> Order interarrival time in minutes: </label>
                    <b-input-group v-if="!isGamePlay">
                        <b-form-select
                            v-model="data.params.order_interarrival_time_type"
                            :options="[
                                { value: 'constant', text: 'Constant' },
                                {
                                    value: 'normal',
                                    text: 'Normal Distribution',
                                },
                                {
                                    value: 'exponential',
                                    text: 'Exponential Distribution',
                                },
                            ]"
                        >
                        </b-form-select>

                        <b-input-group-append>
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-b-popover.hover.top="'Show'"
                                    value="false"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-b-popover.hover.top="'Edit'"
                                    value="false"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>

                    <div
                        v-if="
                            data.params.order_interarrival_time_type ===
                            'constant'
                        "
                    >
                        <number-parameter
                            :data="data"
                            :paramName="'order_interarrival_time_constant'"
                            :icon="'clock'"
                            v-b-popover.hover.top="
                                'The time between orders in minutes'
                            "
                        >
                        </number-parameter>
                    </div>
                    <div v-if="!InterarrivalTimeShown && isGamePlay">
                        <em>
                            One or more parameters have been hidden by the game
                            creator, try deducing these through analysing the
                            simulation units.
                        </em>
                    </div>
                    <div
                        v-else-if="
                            data.params.order_interarrival_time_type ===
                            'normal'
                        "
                    >
                        <number-parameter
                            :data="data"
                            :paramName="'order_interarrival_time_normal_avg'"
                            :icon="'μ'"
                            v-b-popover.hover.top="
                                'The average time between orders in minutes'
                            "
                        >
                        </number-parameter>
                        <number-parameter
                            :data="data"
                            :paramName="'order_interarrival_time_normal_sigma'"
                            :icon="'σ'"
                            v-b-popover.hover.top="
                                'The standard deviation of the time between orders in minutes'
                            "
                        >
                        </number-parameter>
                        <br />
                        <div v-if="!InterarrivalTimeShown && isGamePlay">
                            <em>
                                One or more parameters have been hidden by the
                                game creator, try deducing these through
                                analysing the simulation units.
                            </em>
                        </div>
                    </div>
                    <div
                        v-else-if="
                            data.params.order_interarrival_time_type ===
                            'exponential'
                        "
                    >
                        <number-parameter
                            :data="data"
                            :paramName="'order_interarrival_time_exponential_lambda'"
                            :icon="'λ'"
                            v-b-popover.hover.top="
                                'The parameter λ of the exponentially distributed time between orders'
                            "
                        >
                        </number-parameter>
                        <div v-if="!InterarrivalTimeShown && isGamePlay">
                            <em>
                                One or more parameters have been hidden by the
                                game creator, try deducing these through
                                analysing the simulation units.
                            </em>
                        </div>
                    </div>
                </div>

                <div class="mt-2">
                    <label> Number of boxes per order: </label>
                    <b-input-group v-if="!isGamePlay">
                        <b-form-select
                            v-model="data.params.order_ammout_type"
                            :options="[
                                { value: 'constant', text: 'Constant' },
                                {
                                    value: 'normal',
                                    text: 'Normal Distribution',
                                },
                            ]"
                        >
                        </b-form-select>

                        <b-input-group-append>
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-b-popover.hover.top="'Show'"
                                    value="false"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-b-popover.hover.top="'Edit'"
                                    value="false"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>

                    <div v-if="data.params.order_ammout_type === 'constant'">
                        <number-parameter
                            :data="data"
                            :paramName="'order_ammount_constant'"
                            :icon="'file-text-fill'"
                            v-b-popover.hover.top="
                                'The number of boxes ordered.'
                            "
                        >
                        </number-parameter>
                        <div v-if="!numberOfBoxesShown && isGamePlay">
                            <em>
                                One or more parameters have been hidden by the
                                game creator, try deducing these through
                                analysing the simulation units.
                            </em>
                        </div>
                    </div>
                    <div v-else-if="data.params.order_ammout_type === 'normal'">
                        <number-parameter
                            :data="data"
                            :paramName="'order_ammount_normal_avg'"
                            :icon="'μ'"
                            v-b-popover.hover.top="
                                'The average number of boxes ordered.'
                            "
                        >
                        </number-parameter>
                        <number-parameter
                            :data="data"
                            :paramName="'order_ammount_normal_sigma'"
                            :icon="'σ'"
                            v-b-popover.hover.top="
                                'The standard deviation of number of boxes ordered.'
                            "
                        >
                        </number-parameter>
                        <div v-if="!numberOfBoxesShown && isGamePlay">
                            <em>
                                One or more parameters have been hidden by the
                                game creator, try deducing these through
                                analysing the simulation units.
                            </em>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DeleteUnit from './MetaComponents/DeleteUnit.vue'
import DependencyDropdown from './MetaComponents/DependencyDropdown.vue'
import NameField from './MetaComponents/NameField.vue'
import NumberParameter from './MetaComponents/NumberParameter.vue'
import ShowEdit from './MetaComponents/ShowEdit.vue'

export default {
    components: {
        NameField,
        DependencyDropdown,
        DeleteUnit,
        ShowEdit,
        NumberParameter,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    methods: {},
    computed: {
        isGamePlay: function () {
            return this.$route.name == 'gamePlay'
        },
        InterarrivalTimeShown: function () {
            if (this.data.params.order_interarrival_time_type === 'constant') {
                return this.data.layout.params.order_interarrival_time_constant
                    .show
            } else if (
                this.data.params.order_interarrival_time_type === 'normal'
            ) {
                return (
                    this.data.layout.params.order_interarrival_time_normal_sigma
                        .show &&
                    this.data.layout.params.order_interarrival_time_normal_avg
                        .show
                )
            } else if (
                this.data.params.order_interarrival_time_type === 'exponential'
            ) {
                return this.data.layout.params
                    .order_interarrival_time_exponential_lambda.show
            }
        },
        numberOfBoxesShown: function () {
            if (this.data.params.order_ammout_type === 'constant') {
                return this.data.layout.params.order_ammount_constant.show
            } else if (this.data.params.order_ammout_type === 'normal') {
                return (
                    this.data.layout.params.order_ammount_normal_avg.show &&
                    this.data.layout.params.order_ammount_normal_sigma.show
                )
            }
        },
    },
    created() {
        this.data.layout.params ??= {
            order_interarrival_time_normal_avg: { show: true, edit: false },
            order_interarrival_time_normal_sigma: { show: true, edit: false },
            order_interarrival_time_constant: { show: true, edit: false },
            order_interarrival_time_exponential_lambda: {
                show: true,
                edit: false,
            },
            order_ammount_normal_avg: { show: true, edit: false },
            order_ammount_normal_sigma: { show: true, edit: false },
            order_ammount_constant: { show: true, edit: false },
        }
    },
}
</script>
