import customerImage from './img/customer.svg'
import matcherImage from './img/matcher.svg'
import officeImage from './img/office.svg'
import shipmentImage from './img/shipment.svg'
import supplierImage from './img/supplier.svg'
import warehouseImage from './img/warehouse.svg'
import workshopImage from './img/workshop.svg'

const images = {
    customer: {
        image: customerImage,
        caption: 'Customer',
    },
    matcher: {
        image: matcherImage,
        caption: 'Matcher',
    },
    office: {
        image: officeImage,
        caption: 'Office',
    },
    shipment: {
        image: shipmentImage,
        caption: 'Shipment',
    },
    supplier: {
        image: supplierImage,
        caption: 'Supplier',
    },
    warehouse: {
        image: warehouseImage,
        caption: 'Warehouse',
    },
    workshop: {
        image: workshopImage,
        caption: 'Workshop',
    },
}

export { images }
