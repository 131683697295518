<template>
    <div class="card shadow-sm">
        <div class="card-header">
            Supplier
            <delete-unit
                ref="deleteBtn"
                v-bind:data="data"
                @deletedunit="$emit('deletedunit')"
                @home="$emit('home')"
            >
            </delete-unit>
        </div>
        <div class="card-body small font-weight-light">
            <div v-if="data">
                <name-field
                    :data="data"
                    @redrawnetwork="$emit('redrawnetwork')"
                >
                </name-field>

                <div v-if="showLeadTime" class="mt-2">
                    <label>Delivery time in hours:</label>
                    <b-input-group v-if="!isGamePlay">
                        <b-form-select
                            v-model="data.params.delivery_time_type"
                            :options="[
                                { value: 'constant', text: 'Constant' },
                                {
                                    value: 'normal',
                                    text: 'Normal Distribution',
                                },
                            ]"
                        >
                        </b-form-select>

                        <b-input-group-append>
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-b-popover.hover.top="'Show'"
                                    value="false"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-b-popover.hover.top="'Edit'"
                                    value="false"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                    <div v-if="data.params.delivery_time_type === 'constant'">
                        <number-parameter
                            id="constvalue2"
                            :data="data"
                            :paramName="'delivery_time_constant'"
                            :icon="'clock'"
                            v-b-popover.hover.top="'The delivery time in hours'"
                        >
                        </number-parameter>
                        <div v-if="!dTimeShown && isGamePlay">
                            <em>
                                One or more parameters have been hidden by the
                                game creator, try deducing these through
                                analysing the simulation units.
                            </em>
                        </div>
                    </div>
                    <div
                        v-else-if="data.params.delivery_time_type === 'normal'"
                    >
                        <number-parameter
                            id="avgvalue2"
                            :data="data"
                            :paramName="'delivery_time_normal_avg'"
                            :icon="'μ'"
                            v-b-popover.hover.top="
                                'The average delivery time in hours'
                            "
                        >
                        </number-parameter>
                        <number-parameter
                            id="sdvalue2"
                            :data="data"
                            :paramName="'delivery_time_normal_sigma'"
                            :icon="'σ'"
                            v-b-popover.hover.top="
                                'The standard deviation of the delivery time in hours'
                            "
                        >
                        </number-parameter>
                        <div v-if="!dTimeShown && isGamePlay">
                            <em>
                                One or more parameters have been hidden by the
                                game creator, try deducing these through
                                analysing the simulation units.
                            </em>
                        </div>
                    </div>
                </div>

                <contract
                    :data="data"
                    :waitingSubmitResponse="waitingSubmitResponse"
                    :contract="contract"
                    @submitContractChange="submitContractChange"
                >
                </contract>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from '@/services/api.service'

import NameField from './MetaComponents/NameField.vue'
import DeleteUnit from './MetaComponents/DeleteUnit.vue'
import Contract from './MetaComponents/Contract.vue'
import NumberParameter from './MetaComponents/NumberParameter.vue'
import ShowEdit from './MetaComponents/ShowEdit.vue'

export default {
    components: { NameField, DeleteUnit, Contract, NumberParameter, ShowEdit },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            waitingSubmitResponse: false,
            contract: {
                showTermsKey: 0,
                newName: null,
                defaultTerms: {
                    lead_time: 2,
                    lead_time_max: 3,
                    batch_price: 100,
                    fix_price: 150,
                    batch_size: 10,
                },
            },
        }
    },
    computed: {
        isGamePlay: function () {
            return this.$route.name == 'gamePlay'
        },
        showLeadTime() {
            return (
                this.data.layout.params.delivery_time_constant.show ||
                !this.isGamePlay ||
                this.data.layout.params.delivery_time_normal_avg.show
            )
        },
        dTimeShown() {
            if (this.data.params.delivery_time_type === 'constant') {
                return this.data.layout.params.delivery_time_constant.show
            } else if (this.data.params.delivery_time_type === 'normal') {
                return (
                    this.data.layout.params.delivery_time_normal_avg.show &&
                    this.data.layout.params.delivery_time_normal_sigma.show
                )
            }
        },
    },
    methods: {
        submitContractChange() {
            this.waitingSubmitResponse = true

            ApiService.changeActiveContract(
                this.data.teamId,
                this.data.id,
                this.data.params.contract_active
            ).then(
                (() => {
                    this.$emit('user-action-submitted')
                    this.waitingSubmitResponse = false
                }).bind(this),
                ((error) => {
                    this.$bvModal
                        .msgBoxOk(error.response.data.message, {
                            title: 'Error',
                            centered: true,
                        })
                        .then((success) => {
                            this.waitingSubmitResponse = false
                            this.$emit('user-action-error')
                        })
                }).bind(this)
            )
        },
    },
    created() {
        if (!Object.keys(this.data.params.contracts ?? {}).length) {
            this.data.params.contracts = { Default: this.contract.defaultTerms }
            this.data.params.contract_active = 'Default'
        }

        this.data.layout.params ??= {
            delivery_time_constant: { show: true, edit: false },
            delivery_time_normal_avg: { show: true, edit: false },
            delivery_time_normal_sigma: { show: true, edit: false },
            lead_time: { show: true, edit: false },
            contract: { show: true, edit: false },
        }
    },
}
</script>
