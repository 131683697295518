import moment from 'moment'

export const BracketingBehavior = {
    HashBased: 0,
    RoundRobin: 1,
    MoodleApi: 2,
    LtiParameter: 3,
}

export const BracketingBehaviors = [
    BracketingBehavior.HashBased,
    BracketingBehavior.RoundRobin,
    BracketingBehavior.MoodleApi,
    BracketingBehavior.LtiParameter,
]

function createMap(tuples) {
    const map = {}
    tuples.forEach((tuple) => {
        map[tuple[0]] = tuple[1]
        map[tuple[1]] = tuple[0]
    })

    return map
}

export const BracketingBehaviorDisplayMap = createMap([
    [BracketingBehavior.HashBased, 'Hash based'],
    [BracketingBehavior.RoundRobin, 'Round robin'],
    [BracketingBehavior.MoodleApi, 'Moodle api'],
    [BracketingBehavior.LtiParameter, 'Lti parameter'],
])
export const BracketingBehaviorApiMap = createMap([
    [BracketingBehavior.HashBased, 'HashBased'],
    [BracketingBehavior.RoundRobin, 'RoundRobin'],
    [BracketingBehavior.MoodleApi, 'MoodleApi'],
    [BracketingBehavior.LtiParameter, 'LtiParameter'],
])

export const GameStatus = {
    INITIALIZED: 'INITIALIZED',
    SUSPENDED: 'SUSPENDED',
    STARTED: 'STARTED',
    DONE: 'DONE',
    ABORTED: 'ABORTED',
    ASYNC: 'ASYNC',
}

export const gameStatusBadgeMap = {
    [GameStatus.INITIALIZED]: 'info',
    [GameStatus.STARTED]: 'primary',
    [GameStatus.SUSPENDED]: 'warning',
    [GameStatus.ABORTED]: 'danger',
    [GameStatus.DONE]: 'success',
    [GameStatus.ASYNC]: 'primary',
}

export function formatDateString(str) {
    if (str) {
        return moment(moment.utc(str).toDate()).format('lll')
    } else {
        return ''
    }
}
