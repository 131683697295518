var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card shadow-sm"},[_c('div',{staticClass:"card-header"},[_vm._v(" Customer "),_c('delete-unit',{ref:"deleteBtn",attrs:{"data":_vm.data},on:{"deletedunit":function($event){return _vm.$emit('deletedunit')},"home":function($event){return _vm.$emit('home')}}})],1),_c('div',{staticClass:"card-body small font-weight-light"},[(_vm.data)?_c('div',[_c('name-field',{attrs:{"data":this.data},on:{"redrawnetwork":function($event){return _vm.$emit('redrawnetwork')}}}),_c('dependency-dropdown',{attrs:{"label":"Places orders with:","className":"office","paramName":"office","data":_vm.data},on:{"redrawnetwork":function($event){return _vm.$emit('redrawnetwork')}}}),_c('div',{staticClass:"mt-2"},[_c('label',[_vm._v(" Order interarrival time in minutes: ")]),(!_vm.isGamePlay)?_c('b-input-group',[_c('b-form-select',{attrs:{"options":[
                            { value: 'constant', text: 'Constant' },
                            {
                                value: 'normal',
                                text: 'Normal Distribution',
                            },
                            {
                                value: 'exponential',
                                text: 'Exponential Distribution',
                            } ]},model:{value:(_vm.data.params.order_interarrival_time_type),callback:function ($$v) {_vm.$set(_vm.data.params, "order_interarrival_time_type", $$v)},expression:"data.params.order_interarrival_time_type"}}),_c('b-input-group-append',[_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Show'),expression:"'Show'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"value":"false","disabled":"","switch":""}})],1),_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"value":"false","disabled":"","switch":""}})],1)],1)],1):_vm._e(),(
                        _vm.data.params.order_interarrival_time_type ===
                        'constant'
                    )?_c('div',[_c('number-parameter',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                            'The time between orders in minutes'
                        ),expression:"\n                            'The time between orders in minutes'\n                        ",modifiers:{"hover":true,"top":true}}],attrs:{"data":_vm.data,"paramName":'order_interarrival_time_constant',"icon":'clock'}})],1):_vm._e(),(!_vm.InterarrivalTimeShown && _vm.isGamePlay)?_c('div',[_c('em',[_vm._v(" One or more parameters have been hidden by the game creator, try deducing these through analysing the simulation units. ")])]):(
                        _vm.data.params.order_interarrival_time_type ===
                        'normal'
                    )?_c('div',[_c('number-parameter',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                            'The average time between orders in minutes'
                        ),expression:"\n                            'The average time between orders in minutes'\n                        ",modifiers:{"hover":true,"top":true}}],attrs:{"data":_vm.data,"paramName":'order_interarrival_time_normal_avg',"icon":'μ'}}),_c('number-parameter',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                            'The standard deviation of the time between orders in minutes'
                        ),expression:"\n                            'The standard deviation of the time between orders in minutes'\n                        ",modifiers:{"hover":true,"top":true}}],attrs:{"data":_vm.data,"paramName":'order_interarrival_time_normal_sigma',"icon":'σ'}}),_c('br'),(!_vm.InterarrivalTimeShown && _vm.isGamePlay)?_c('div',[_c('em',[_vm._v(" One or more parameters have been hidden by the game creator, try deducing these through analysing the simulation units. ")])]):_vm._e()],1):(
                        _vm.data.params.order_interarrival_time_type ===
                        'exponential'
                    )?_c('div',[_c('number-parameter',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                            'The parameter λ of the exponentially distributed time between orders'
                        ),expression:"\n                            'The parameter λ of the exponentially distributed time between orders'\n                        ",modifiers:{"hover":true,"top":true}}],attrs:{"data":_vm.data,"paramName":'order_interarrival_time_exponential_lambda',"icon":'λ'}}),(!_vm.InterarrivalTimeShown && _vm.isGamePlay)?_c('div',[_c('em',[_vm._v(" One or more parameters have been hidden by the game creator, try deducing these through analysing the simulation units. ")])]):_vm._e()],1):_vm._e()],1),_c('div',{staticClass:"mt-2"},[_c('label',[_vm._v(" Number of boxes per order: ")]),(!_vm.isGamePlay)?_c('b-input-group',[_c('b-form-select',{attrs:{"options":[
                            { value: 'constant', text: 'Constant' },
                            {
                                value: 'normal',
                                text: 'Normal Distribution',
                            } ]},model:{value:(_vm.data.params.order_ammout_type),callback:function ($$v) {_vm.$set(_vm.data.params, "order_ammout_type", $$v)},expression:"data.params.order_ammout_type"}}),_c('b-input-group-append',[_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Show'),expression:"'Show'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"value":"false","disabled":"","switch":""}})],1),_c('b-input-group-text',[_c('b-form-checkbox',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-n2",attrs:{"value":"false","disabled":"","switch":""}})],1)],1)],1):_vm._e(),(_vm.data.params.order_ammout_type === 'constant')?_c('div',[_c('number-parameter',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                            'The number of boxes ordered.'
                        ),expression:"\n                            'The number of boxes ordered.'\n                        ",modifiers:{"hover":true,"top":true}}],attrs:{"data":_vm.data,"paramName":'order_ammount_constant',"icon":'file-text-fill'}}),(!_vm.numberOfBoxesShown && _vm.isGamePlay)?_c('div',[_c('em',[_vm._v(" One or more parameters have been hidden by the game creator, try deducing these through analysing the simulation units. ")])]):_vm._e()],1):(_vm.data.params.order_ammout_type === 'normal')?_c('div',[_c('number-parameter',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                            'The average number of boxes ordered.'
                        ),expression:"\n                            'The average number of boxes ordered.'\n                        ",modifiers:{"hover":true,"top":true}}],attrs:{"data":_vm.data,"paramName":'order_ammount_normal_avg',"icon":'μ'}}),_c('number-parameter',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                            'The standard deviation of number of boxes ordered.'
                        ),expression:"\n                            'The standard deviation of number of boxes ordered.'\n                        ",modifiers:{"hover":true,"top":true}}],attrs:{"data":_vm.data,"paramName":'order_ammount_normal_sigma',"icon":'σ'}}),(!_vm.numberOfBoxesShown && _vm.isGamePlay)?_c('div',[_c('em',[_vm._v(" One or more parameters have been hidden by the game creator, try deducing these through analysing the simulation units. ")])]):_vm._e()],1):_vm._e()],1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }