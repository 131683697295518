import router from '../router'
import ApiService from '../services/api.service'

export const auth = {
    state: {
        tokens: {
            access: null,
            refresh: null,
        },
        user: {
            id: null,
            roles: [],
        },
    },
    getters: {
        userLoggedIn: (state) => !!state.tokens.access,
        userHasRole: (state) => (role) => {
            return state.user.roles.includes(role)
        },
    },
    mutations: {
        login(state, { accessToken, refreshToken, userId, userRoles }) {
            state.user.id = userId
            state.tokens.access = accessToken

            if (!!refreshToken) {
                state.tokens.refresh = refreshToken
            }

            if (!!userRoles) {
                state.user.roles = userRoles
            }
        },
        logout(state) {
            state.tokens.access = null
            state.tokens.refresh = null
        },
    },
    actions: {
        login({ commit }, user) {
            return ApiService.login(user).then(
                (response) => {
                    commit('login', {
                        accessToken: response.data.auth.tokens.access,
                        refreshToken: response.data.auth.tokens.refresh,
                        userId: response.data.auth.user.id,
                        userRoles: response.data.auth.user.roles,
                    })
                    console.log('user has logged in')

                    return Promise.resolve(response)
                },
                (error) => {
                    console.log('user failed to login')
                    router.push('/login').catch((err) => {})

                    return Promise.reject(error)
                }
            )
        },
        logout({ commit }) {
            ApiService.logout().then(
                (response) => {
                    console.log('user has logged out')
                    commit('logout')
                    router.push('/login').catch((err) => {})
                },
                (error) => {
                    console.log('user failed to logout')
                }
            )
        },
        register({ commit }, user) {
            return ApiService.register(user).then(
                (response) => {
                    console.log('user has been registered')

                    return Promise.resolve(response)
                },
                (error) => {
                    console.log('user failed to register')

                    return Promise.reject(error)
                }
            )
        },
    },
}
