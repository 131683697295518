// change the name in dependants
function renameUnitDep(units, className, paramName, oldName, newName) {
    for (let unitName in units) {
        const unit = units[unitName]
        if (unit.class === className) {
            if (unit.params[paramName] === oldName) {
                unit.params[paramName] = newName
            }
        }
    }
}

export function changeValueOfDependents(units, ownClass, ownId, newValue) {
    switch (ownClass) {
        case 'customer':
            break
        case 'matcher':
            break
        case 'office':
            // rename all refrences in other customers
            renameUnitDep(units, 'customer', 'office', ownId, newValue)
            break
        case 'shipment':
            break
        case 'supplier':
            // rename all refrences in other warehouses
            renameUnitDep(units, 'warehouse', 'supplier', ownId, newValue)
            break
        case 'warehouse':
            renameUnitDep(units, 'supplier', 'warehouse', ownId, newValue)
            renameUnitDep(units, 'matcher', 'warehouse', ownId, newValue)
        case 'workshop':
            break
    }
}
