<template>
    <div class="card shadow-sm">
        <div class="card-header">
            Matcher
            <delete-unit
                ref="deleteBtn"
                v-bind:data="data"
                @deletedunit="$emit('deletedunit')"
                @home="$emit('home')"
            >
            </delete-unit>
        </div>
        <div class="card-body small font-weight-light">
            <div v-if="data">
                <name-field
                    :data="data"
                    @redrawnetwork="$emit('redrawnetwork')"
                >
                </name-field>

                <dependency-dropdown
                    @redrawnetwork="$emit('redrawnetwork')"
                    label="Wearhouse:"
                    className="warehouse"
                    paramName="warehouse"
                    :data="data"
                >
                </dependency-dropdown>

                <div v-if="showShortageCosts" class="mt-2">
                    <label>Shortage costs per order:</label>

                    <b-input-group
                        v-b-popover.hover.top="
                            'A cost to be payed once for each order that is not immediately processed'
                        "
                    >
                        <b-input-group-prepend is-text>
                            <span class="mx-1">$</span>
                        </b-input-group-prepend>

                        <b-form-input
                            v-model="data.params.shortage_cost"
                            :disabled="isGamePlay"
                            type="number"
                            :number="true"
                            min="0"
                        >
                        </b-form-input>

                        <show-edit
                            :data="data"
                            :paramName="'shortage_cost'"
                            :disallowAllEdit="'true'"
                        ></show-edit>
                    </b-input-group>
                </div>

                <queue-policy
                    :data="data"
                    paramName="queue_policy"
                    :waitingSubmitResponse="waitingSubmitResponse"
                    @contractChange="submitQueuePolicy"
                >
                </queue-policy>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from '@/services/api.service'

import NameField from './MetaComponents/NameField.vue'
import DependencyDropdown from './MetaComponents/DependencyDropdown.vue'
import DeleteUnit from './MetaComponents/DeleteUnit.vue'
import QueuePolicy from './MetaComponents/QueuePolicy.vue'
import ShowEdit from './MetaComponents/ShowEdit.vue'

export default {
    components: {
        NameField,
        DependencyDropdown,
        DeleteUnit,
        QueuePolicy,
        ShowEdit,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            waitingSubmitResponse: false,
        }
    },
    computed: {
        isGamePlay: function () {
            return this.$route.name == 'gamePlay'
        },
        showShortageCosts: function () {
            return (
                this.data.layout.params.shortage_cost.show || !this.isGamePlay
            )
        },
    },
    methods: {
        submitQueuePolicy() {
            this.waitingSubmitResponse = true

            ApiService.changeQueuePolicy(
                this.data.teamId,
                this.data.id,
                this.data.params.queue_policy
            ).then(
                (() => {
                    this.waitingSubmitResponse = false
                    this.$emit('user-action-submitted')
                }).bind(this),
                ((error) => {
                    this.$bvModal
                        .msgBoxOk(error.response.data.message, {
                            title: 'Error',
                            centered: true,
                        })
                        .then((success) => {
                            this.waitingSubmitResponse = false
                            this.$emit('user-action-error')
                        })
                }).bind(this)
            )
        },
    },
    created() {
        this.data.layout.params ??= {
            queue_policy: { show: true, edit: true },
            shortage_cost: { show: true, edit: false },
        }
    },
}
</script>
