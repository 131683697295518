<template>
    <div v-if="ready" class="container">
        <div class="row row-cols-1">
            <div class="col">
                <data-export-button
                    class="rounded border border-info"
                    name="Closed Orders"
                    :data="closedOrders"
                >
                    Closed orders
                </data-export-button>
            </div>
        </div>

        <line-chart
            :width="700"
            :height="180"
            v-bind:chartData="cash.chartData"
            :options="cash.options"
        />

        <line-chart
            :width="700"
            :height="180"
            v-bind:chartData="cost.chartData"
            :options="cost.options"
        />

        <line-chart
            :width="700"
            :height="180"
            :chartData="wip.chartData"
            :options="wip.options"
        />
    </div>
</template>
<script>
import LineChart from './Plots/LineChart.vue'
import BarChart from './Plots/BarChart.vue'
import DataExportButton from './Plots/DataExportButton.vue'
import mixins from './Plots/mixins'

export default {
    components: { LineChart, BarChart, DataExportButton },
    mixins: [mixins.plotDataMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        maintainAspectRatio: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            cash: {
                chartData: null,
                options: {
                    title: {
                        display: true,
                        text: 'Capital at the end of the day',
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    maxTicksLimit: 10,
                                },
                                gridLines: {
                                    display: true,
                                },
                                scaleLabel: {
                                    display: true,
                                    fontStyle: 'italic',
                                    labelString: 'Cash value [dollars]',
                                },
                            },
                        ],
                        xAxes: [
                            {
                                gridLines: {
                                    display: false,
                                },
                                scaleLabel: {
                                    display: true,
                                    fontStyle: 'italic',
                                    labelString: 'Day',
                                },
                            },
                        ],
                    },
                    legend: {
                        display: false,
                    },
                    responsive: true,
                    maintainAspectRatio: this.maintainAspectRatio,
                },
            },
            cost: {
                chartData: null,
                options: {
                    title: {
                        display: true,
                        text: 'Sum of all costs payed at the end of the day',
                    },
                    scales: {
                        yAxes: [
                            {
                                stacked: true,
                                ticks: {
                                    beginAtZero: true,
                                    maxTicksLimit: 10,
                                },
                                gridLines: {
                                    display: true,
                                },
                                scaleLabel: {
                                    display: true,
                                    fontStyle: 'italic',
                                    labelString: 'Cash value [dollars]',
                                },
                            },
                        ],
                        xAxes: [
                            {
                                stacked: true,
                                gridLines: {
                                    display: false,
                                },
                                scaleLabel: {
                                    display: true,
                                    fontStyle: 'italic',
                                    labelString: 'Day',
                                },
                            },
                        ],
                    },
                    legend: {
                        display: true,
                    },
                    responsive: true,
                    maintainAspectRatio: this.maintainAspectRatio,
                },
            },
            wip: {
                chartData: null,
                options: {
                    title: {
                        display: true,
                        text: 'Number of orders in the system at the end of the day',
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    maxTicksLimit: 10,
                                },
                                gridLines: {
                                    display: true,
                                },
                                scaleLabel: {
                                    display: true,
                                    fontStyle: 'italic',
                                    labelString: 'Number of orders [boxes]',
                                },
                            },
                        ],
                        xAxes: [
                            {
                                gridLines: {
                                    display: false,
                                },
                                scaleLabel: {
                                    display: true,
                                    fontStyle: 'italic',
                                    labelString: 'Day',
                                },
                            },
                        ],
                    },
                    legend: {
                        display: false,
                    },
                    responsive: true,
                    maintainAspectRatio: this.maintainAspectRatio,
                },
            },
        }
    },
    computed: {
        ready: function () {
            return (
                !!this.cash.chartData &&
                !!this.wip.chartData &&
                !!this.cost.chartData
            )
        },
        closedOrders: function () {
            var orders = []

            for (var i = 0; i < this.data.stats.length; i++) {
                let data = this.data.stats[i]

                if (!data.closed_orders) {
                    continue
                }

                for (var order of data.closed_orders) {
                    orders.push({
                        'accepted at': order.accepted_at_formatted,
                        'finished at': order.finished_at_formatted,
                        'asked lead time': order.lead_time,
                        'maximum lead time': order.lead_time_max,
                        'actual lead time': order.actual_lead_time,
                        revenue: order.revenue,
                    })
                }
            }

            return orders
        },
    },
    methods: {
        loadCashData() {
            let { stats, labels } = this.getRecentStats()
            this.cash.chartData = {
                labels: labels,
                datasets: [
                    {
                        label: 'Cash',
                        data: stats.map((s) => s.cash),
                        backgroundColor: 'rgb(0, 100, 128, 0.5)',
                        lineTension: 0,
                        pointRadius: 0,
                    },
                ],
            }
        },
        loadCostData() {
            let { stats, labels } = this.getRecentStats()
            this.cost.chartData = {
                labels: labels,
                datasets: [
                    {
                        label: 'Supplier orders',
                        data: stats.map((s) => s['cost order']),
                        backgroundColor: 'rgb(0, 128, 128, 1)',
                        steppedLine: 'middle',
                        lineTension: 0,
                        pointRadius: 0,
                    },
                    {
                        label: 'Shipment storage',
                        data: stats.map((s) => s['cost shipment storage']),
                        backgroundColor: 'rgb(0, 128, 0, 1)',
                        steppedLine: 'middle',
                        lineTension: 0,
                        pointRadius: 0,
                    },
                    {
                        label: 'Warehouse storage',
                        data: stats.map((s) => s['cost warehouse storage']),
                        backgroundColor: 'rgb(0, 0, 128, 1)',
                        steppedLine: 'middle',
                        lineTension: 0,
                        pointRadius: 0,
                    },
                    {
                        label: 'Machine purchases',
                        data: stats.map((s) => s['cost machine purchase']),
                        backgroundColor: 'rgb(128, 0, 0, 1)',
                        steppedLine: 'middle',
                        lineTension: 0,
                        pointRadius: 0,
                    },
                    {
                        label: 'Shortage cost',
                        data: stats.map((s) => s['cost shortage']),
                        backgroundColor: 'rgb(128, 128, 0, 1)',
                        steppedLine: 'middle',
                        lineTension: 0,
                        pointRadius: 0,
                    },
                ],
            }
        },
        loadWipData() {
            let { stats, labels } = this.getRecentStats()
            this.wip.chartData = {
                labels: labels,
                datasets: [
                    {
                        label: 'Orders',
                        data: stats.map((s) => s.wip_orders),
                        backgroundColor: 'rgb(100, 100, 100, 0.5)',
                        lineTension: 0,
                        pointRadius: 0,
                    },
                ],
            }
        },
        initChartData() {
            this.loadCashData()
            this.loadWipData()
            this.loadCostData()
        },
    },
    mounted() {
        this.initChartData()
    },
    watch: {
        data: function () {
            if (!!this.data) {
                this.initChartData()
            }
        },
    },
}
</script>
