import { GameStatus } from '@/views'

const gameTemplate = {
    title: 'The Game',
    description: 'This is a default game.',
    status: GameStatus.INITIALIZED,
    bracketing_behavior: 'MoodleApi',
    number_of_brackets: 3,
    leaderboard: true,
    layout: {
        Customer: {
            position: { x: 30, y: 250 },
        },
        'Sales Office': {
            position: { x: 140, y: 250 },
        },
        'Inv. Matcher': {
            position: { x: 250, y: 250 },
        },
        Shipment: {
            position: { x: 450, y: 250 },
        },
        Supplier: {
            position: { x: 400, y: 95 },
        },
        Warehouse: {
            position: { x: 270, y: 160 },
        },
        Workshop: {
            position: { x: 350, y: 250 },
        },
    },
    simulation: {
        template: {
            seed: 0,
            clock: {
                prior_days: 50,
                play_days: 100,
                post_days: 50,
                days_per_hour: 1,
            },
            plant: {
                wip_orders_max: 5000,
                cash: 1000000,
                units: {
                    Workshop: {
                        class: 'workshop',
                        params: {
                            machine: {
                                purchase_price: 80000,
                                retire_price: 50000,
                                item_processing_time_type: 'constant',
                                item_processing_time_constant: 10,
                                item_processing_time_avg: 10,
                                item_processing_time_sigma: 1,
                                breakdown: {
                                    proba: 0.25,
                                },
                                lot_setup_time_type: 'constant',
                                lot_setup_time_constant: 5,
                                lot_setup_time_avg: 5,
                                lot_setup_time_sigma: 1,
                                breakdown_type: 'constant',
                                breakdown_constant: 5,
                                breakdown_avg: 0,
                                breakdown_sigma: 0.25,
                            },
                            machine_quantity: 5,
                            queue_policy: 'fifo',
                        },
                    },
                    Warehouse: {
                        class: 'warehouse',
                        params: {
                            supplier: 'Supplier',
                            reorder_point: 2500,
                            order_size: 1,
                            inventory: 5000,
                            unit_storage_cost: 0.5,
                        },
                    },
                    'Sales Office': {
                        class: 'office',
                        params: {
                            lot_size: 10,
                            contracts: {
                                Default: {
                                    lead_time: 72,
                                    lead_time_max: 120,
                                    batch_price: 200,
                                    fix_price: 100,
                                    batch_size: 10,
                                },
                                lucrative: {
                                    lead_time: 48,
                                    lead_time_max: 72,
                                    batch_price: 250,
                                    fix_price: 150,
                                    batch_size: 10,
                                },
                            },
                            contract_active: 'Default',
                            queue_policy: 'fifo',
                        },
                    },
                    Customer: {
                        class: 'customer',
                        params: {
                            order_interarrival_time_normal_avg: 60,
                            order_interarrival_time_normal_sigma: 0,
                            order_interarrival_time_constant: 60,
                            order_interarrival_time_exponential_lambda: 5,
                            order_interarrival_time_type: 'constant',

                            order_ammount_normal_avg: 1,
                            order_ammount_normal_sigma: 0,
                            order_ammount_constant: 1,
                            order_ammout_type: 'constant',
                            office: 'Sales Office',
                        },
                    },
                    Supplier: {
                        class: 'supplier',
                        params: {
                            warehouse: 'Warehouse',
                            delivery_time_type: 'constant',
                            delivery_time_constant: 72,
                            delivery_time_normal_avg: 72,
                            delivery_time_normal_sigma: 24,
                            lot_size: 1,
                            contracts: {
                                Default: {
                                    lead_time: 48,
                                    lead_time_max: 72,
                                    batch_price: 100,
                                    fix_price: 150,
                                    batch_size: 10,
                                },
                            },
                            contract_active: 'Default',
                            queue_policy: 'fifo',
                        },
                    },
                    Shipment: {
                        class: 'shipment',
                        params: {
                            unit_storage_cost: 0,
                            queue_policy: 'fifo',
                        },
                    },
                    'Inv. Matcher': {
                        class: 'matcher',
                        params: {
                            warehouse: 'Warehouse',
                            queue_policy: 'fifo',
                            shortage_cost: 0,
                        },
                    },
                },
                pipeline: {
                    'Product XYZ': [
                        {
                            unit: 'Sales Office',
                        },
                        {
                            unit: 'Inv. Matcher',
                        },
                        {
                            unit: 'Workshop',
                        },
                        {
                            unit: 'Shipment',
                        },
                    ],
                },
            },
        },
    },
}

export { gameTemplate }
