<template>
    <div class="d-flex">
        <div v-b-popover.hover.top="'Download as XLS'">
            <json-excel
                class="btn btn-default"
                :data="data"
                :fields="fields"
                :name="filename + '.xls'"
                :escapeCsv="false"
                type="xls"
            >
                <b-icon icon="download" font-scale="0.85"></b-icon>
                <span class="text-muted small ml-2">
                    <slot></slot>
                </span>
            </json-excel>
        </div>
        <div v-b-popover.hover.top="'Download as CSV'">
            <json-excel
                class="btn btn-default"
                :data="data"
                :fields="fields"
                :name="filename + '.csv'"
                :escapeCsv="false"
                type="csv"
            >
                <b-icon icon="download" font-scale="0.85"></b-icon>
                <span class="text-muted small ml-2">
                    <slot></slot>
                </span>
            </json-excel>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import JsonExcel from 'vue-json-excel'

export default {
    name: 'data-export-button',
    components: { JsonExcel },
    props: {
        name: {
            type: String,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        fields: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            timestamp: null,
        }
    },
    computed: {
        filename: function () {
            return `${this.name} - ${this.timestamp}`
        },
    },
    methods: {
        updateTimestamp() {
            this.timestamp = moment().format('D MMM YYYY HH[h]mm[m]ss[s]')
            this.scheduleTimestampUpdate()
        },
        scheduleTimestampUpdate() {
            setTimeout(this.updateTimestamp, 1000)
        },
    },
    created() {
        this.scheduleTimestampUpdate()
    },
}
</script>
