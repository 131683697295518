<template>
    <div class="card shadow-sm">
        <div class="card-header">
            Warehouse
            <delete-unit
                ref="deleteBtn"
                v-bind:data="data"
                @deletedunit="$emit('deletedunit')"
                @home="$emit('home')"
            >
            </delete-unit>
        </div>
        <div class="card-body small font-weight-light">
            <div v-if="data">
                <name-field
                    :data="data"
                    @redrawnetwork="$emit('redrawnetwork')"
                >
                </name-field>

                <div v-if="this.$route.name !== 'gamePlay'">
                    <label>Orders from:</label>
                    <b-input-group>
                        <b-form-select
                            :options="supplierOptions"
                            @change="onSupplierChange"
                            :value="this.data.params.supplier"
                        >
                        </b-form-select>

                        <b-input-group-append>
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-b-popover.hover.top="'Show'"
                                    value="false"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                            <b-input-group-text>
                                <b-form-checkbox
                                    class="mr-n2"
                                    v-b-popover.hover.top="'Edit'"
                                    value="false"
                                    disabled
                                    switch
                                >
                                </b-form-checkbox>
                            </b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                </div>

                <div class="mt-2">
                    <label>{{
                        isGamePlay
                            ? 'Items in inventory'
                            : 'Initial inventory in items'
                    }}</label>

                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <b-icon icon="grid-fill" aria-hidden="true">
                            </b-icon>
                        </b-input-group-prepend>

                        <b-form-input
                            v-model="data.params.inventory"
                            :disabled="isGamePlay"
                            :number="true"
                            type="number"
                            min="0"
                        >
                        </b-form-input>

                        <show-edit
                            :data="data"
                            :paramName="'inventory'"
                            :disallowAllEdit="'true'"
                            :disallowHide="'true'"
                        >
                        </show-edit>
                    </b-input-group>
                </div>
                <div v-if="showUnitStorageCost" class="mt-2">
                    <label>Storage costs per item per day:</label>

                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <span class="mx-1">$</span>
                        </b-input-group-prepend>

                        <b-form-input
                            v-model="data.params.unit_storage_cost"
                            :disabled="isGamePlay"
                            type="number"
                            min="0"
                        >
                        </b-form-input>

                        <show-edit
                            :data="data"
                            :paramName="'unit_storage_cost'"
                            :disallowAllEdit="'true'"
                        >
                        </show-edit>
                    </b-input-group>
                </div>
                <div v-if="showReorderPoint" class="mt-2">
                    <label>Reorder point in items (s):</label>

                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <b-icon icon="arrows-collapse" aria-hidden="true">
                            </b-icon>
                        </b-input-group-prepend>

                        <b-form-input
                            :class="{
                                'is-invalid': !Number.isInteger(
                                    data.params.reorder_point
                                ),
                                'is-valid': Number.isInteger(
                                    data.params.reorder_point
                                ),
                            }"
                            aria-describedby="validationFeedback"
                            v-model="data.params.reorder_point"
                            :disabled="!editReorderPoint"
                            :number="true"
                            type="number"
                            min="0"
                        >
                        </b-form-input>
                        <div id="validationFeedback" class="invalid-feedback">
                            Reorder point must be a whole number.
                        </div>

                        <b-input-group-append>
                            <show-edit
                                :data="data"
                                :paramName="'reorder_point'"
                            >
                            </show-edit>

                            <b-button
                                v-if="
                                    isGamePlay &&
                                    Number.isInteger(data.params.reorder_point)
                                "
                                variant="success"
                                :disabled="
                                    !editReorderPoint || waitingSubmitResponse
                                "
                                @click="submitReorderPoint"
                            >
                                Submit
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>
                <div v-if="showOrderSize" class="mt-2">
                    <label>Number of boxes per order (Q):</label>

                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <b-icon icon="file-text-fill" aria-hidden="true">
                            </b-icon>
                        </b-input-group-prepend>

                        <b-form-input
                            :class="{
                                'is-invalid': !Number.isInteger(
                                    data.params.order_size
                                ),
                                'is-valid': Number.isInteger(
                                    data.params.order_size
                                ),
                            }"
                            aria-describedby="validFeedback"
                            v-model="data.params.order_size"
                            :disabled="!editOrderSize"
                            :number="true"
                            type="number"
                            min="1"
                        >
                        </b-form-input>
                        <div id="validFeedback" class="invalid-feedback">
                            Order size must be a whole number.
                        </div>
                        <b-input-group-append>
                            <show-edit :data="data" :paramName="'order_size'">
                            </show-edit>

                            <b-button
                                v-if="
                                    isGamePlay &&
                                    Number.isInteger(data.params.order_size)
                                "
                                variant="success"
                                :disabled="
                                    !editOrderSize || waitingSubmitResponse
                                "
                                @click="submitOrderSize"
                            >
                                Submit
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from '@/services/api.service'
import { GameStatus } from '@/views'
import NameField from './MetaComponents/NameField.vue'
import DeleteUnit from './MetaComponents/DeleteUnit.vue'
import ShowEdit from './MetaComponents/ShowEdit.vue'

export default {
    components: { NameField, DeleteUnit, ShowEdit },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            waitingSubmitResponse: false,
        }
    },
    computed: {
        isGamePlay: function () {
            return this.$route.name == 'gamePlay'
        },
        isGameStarted: function () {
            return (
                this.data.gameStatus == GameStatus.STARTED ||
                this.data.gameStatus == GameStatus.ASYNC
            )
        },
        showReorderPoint: function () {
            return (
                this.data.layout.params.reorder_point.show || !this.isGamePlay
            )
        },
        editReorderPoint: function () {
            if (!this.isGamePlay) {
                return true
            }

            return (
                this.data.layout.params.reorder_point.edit && this.isGameStarted
            )
        },
        showOrderSize: function () {
            return this.data.layout.params.order_size.show || !this.isGamePlay
        },
        editOrderSize: function () {
            if (!this.isGamePlay) {
                return true
            }

            return this.data.layout.params.order_size.edit && this.isGameStarted
        },
        showUnitStorageCost: function () {
            return (
                this.data.layout.params.unit_storage_cost.show ||
                !this.isGamePlay
            )
        },
        supplierOptions() {
            const units = this.data.game.simulation.template.plant.units
            let dropDownUnits = []

            for (let unitName in units) {
                const unit = units[unitName]
                if (unit.class === 'supplier') {
                    dropDownUnits.push({ value: unitName, text: unitName })
                }
            }

            dropDownUnits.push({
                value: undefined,
                text: 'N/A',
                disabled: true,
            })

            return dropDownUnits
        },
    },
    methods: {
        submitReorderPoint() {
            this.waitingSubmitResponse = true

            ApiService.changeWarehouseReorderPoint(
                this.data.teamId,
                this.data.id,
                this.data.params.reorder_point.toString()
            ).then(
                (() => {
                    this.$emit('user-action-submitted')
                    this.waitingSubmitResponse = false
                }).bind(this),
                ((error) => {
                    this.$bvModal
                        .msgBoxOk(error.response.data.message, {
                            title: 'Error',
                            centered: true,
                        })
                        .then((success) => {
                            this.waitingSubmitResponse = false
                            this.$emit('user-action-error')
                        })
                }).bind(this)
            )
        },
        submitOrderSize() {
            this.waitingSubmitResponse = true

            ApiService.changeWarehouseOrderSize(
                this.data.teamId,
                this.data.id,
                this.data.params.order_size.toString()
            ).then(
                (() => {
                    this.$emit('user-action-submitted')
                    this.waitingSubmitResponse = false
                }).bind(this),
                ((error) => {
                    this.$bvModal
                        .msgBoxOk(error.response.data.message, {
                            title: 'Error',
                            centered: true,
                        })
                        .then((success) => {
                            this.waitingSubmitResponse = false
                            this.$emit('user-action-error')
                        })
                }).bind(this)
            )
        },
        onSupplierChange(newSupplier) {
            if (this.data.params.supplier === newSupplier) {
                return
            }

            const units = this.data.game.simulation.template.plant.units
            const oldSupplier = this.data.params.supplier

            // delete the old suppliers reference to this wearhouse
            if (oldSupplier) {
                units[oldSupplier].params.warehouse = undefined
            }

            // delete the new suppliers wearhouses refrence to it
            if (units[newSupplier].params.warehouse) {
                const wearhouseOfNew =
                    units[units[newSupplier].params.warehouse]
                wearhouseOfNew.params.supplier = undefined
            }

            // change the new suppliers refrence to this wearhouse
            units[newSupplier].params.warehouse = this.data.id

            // change the wearhouses refrence to the supplier
            this.data.params.supplier = newSupplier

            this.$emit('redrawnetwork')
        },
    },
    created() {
        this.data.layout.params ??= {
            inventory: { show: true, edit: false },
            order_size: { show: true, edit: true },
            reorder_point: { show: true, edit: true },
            unit_storage_cost: { show: true, edit: false },
        }
    },
}
</script>
