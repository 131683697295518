<template>
    <div class="container container-fluid">
        <div v-if="!game" class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            Please wait. The layout is loading...
        </div>
        <div v-else class="row">
            <div class="col pl-0 mb-4">
                <div
                    v-if="selectedPlantUnit"
                    class="sticky-top scroll scrollbar-hidden"
                >
                    <component
                        ref="unitParam"
                        v-bind:data="selectedPlantUnit"
                        v-bind:is="selectedPlantUnitParamsComponent"
                        @user-action-error="onUserActionError"
                        @user-action-submitted="onUserActionSubmitted"
                        @redrawnetwork="redrawnetwork"
                        @deletedunit="deletedunit"
                        @home="home"
                    >
                    </component>
                </div>
                <div v-else class="flex-grow-1">
                    <game-info :game="game" :teamName="teamName" :cash="cash">
                    </game-info>
                    <pipelines
                        v-if="this.$route.name !== 'gamePlay'"
                        :game="game"
                        @redrawnetwork="redrawnetwork"
                    >
                    </pipelines>
                </div>
            </div>

            <div class="col-md-7 p-0">
                <div v-if="editable" class="row-md-8">
                    <div class="card shadow-sm mb-3">
                        <div class="card-body pb-0">
                            <div
                                class="d-flex justify-content-between w-100 small"
                            >
                                <figure
                                    v-for="i in layout.images"
                                    class="figure text-center grabbing"
                                    draggable="true"
                                    @dragstart="dragStart($event, i.caption)"
                                    v-bind:key="i.caption"
                                >
                                    <img
                                        class="image"
                                        :src="i.image"
                                        width="30"
                                        height="30"
                                    />
                                    <figcaption class="figure-caption">
                                        {{ i.caption }}
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    id="canvas"
                    class="rounded border"
                    @drop="onDrop($event)"
                    @dragover.prevent
                    @dragenter.prevent
                    @keyup="canvasKeyUp"
                >
                    <network
                        style="width: 100%"
                        ref="network"
                        :key="visNetworkKey"
                        :nodes="layout.nodes"
                        :edges="layout.edges"
                        :options="layout.options"
                        :events="canvasEvents"
                        @drag-end="onNodeDragEnd"
                        @hover-node="onNodeHover"
                        @blur-node="onNodeBlur"
                        @select-node="onNodeSelect"
                        @deselect-node="onNodeDeselect"
                        @double-click="onCanvasDoubleClick"
                    >
                    </network>
                </div>

                <div class="mt-3">
                    <div v-if="pinnedPlots.length">
                        <div
                            v-for="plot in pinnedPlots"
                            class="card shadow-sm mb-3"
                        >
                            <div class="card-body p-1">
                                <div class="clearfix">
                                    <div
                                        class="btn btn-default float-right"
                                        @click="() => closePinnedPlot(plot.id)"
                                    >
                                        <b-icon
                                            icon="x-circle"
                                            font-scale="0.85"
                                        >
                                        </b-icon>
                                    </div>

                                    <div class="float-right">
                                        <data-export-button
                                            :name="plot.id"
                                            :data="
                                                addDays(
                                                    plot.data.stats.map(
                                                        removeNestedProps
                                                    )
                                                )
                                            "
                                        >
                                        </data-export-button>
                                    </div>
                                </div>

                                <div class="text-center text-muted">
                                    {{ plot.id }}
                                </div>

                                <component
                                    :is="plot.component"
                                    :data="plot.data"
                                    :maintainAspectRatio="false"
                                >
                                </component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal
            ref="showPlotsModal"
            id="show-plots-modal"
            size="xl"
            :title="plotsModalTitle"
            ok-only
            centered
            hide-footer
            hide-header
            body-class="p-1 mb-2"
        >
            <div v-if="selectedPlantUnit">
                <div class="clearfix">
                    <div
                        class="btn btn-default float-right"
                        @click="closePlotsModal"
                    >
                        <b-icon icon="x-circle" font-scale="0.85"></b-icon>
                    </div>

                    <div
                        class="btn btn-default float-right"
                        v-b-popover.hover.top="'Pin Plot'"
                        @click="pinPlotsModal"
                    >
                        <b-icon icon="view-list" font-scale="0.85"></b-icon>
                    </div>

                    <div class="float-right">
                        <data-export-button
                            :name="selectedPlantUnit.id"
                            :data="
                                addDays(
                                    selectedPlantUnitStats.stats.map(
                                        removeNestedProps
                                    )
                                )
                            "
                        >
                        </data-export-button>
                    </div>
                </div>

                <div class="text-center text-muted">
                    {{ selectedPlantUnit.id }}
                </div>

                <component
                    :is="selectedPlantUnitPlotsComponent"
                    :data="selectedPlantUnitStats"
                >
                </component>
            </div>

            <template #modal-footer>
                <div></div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { images } from '@/assets/'
import DataExportButton from './Units/Plots/DataExportButton.vue'
import Vue from 'vue'
import Pipelines from './Units/MetaComponents/Pipelines.vue'
import { addDays } from './index'
import { gameTemplate } from './Game/template'

export default {
    components: { DataExportButton, Pipelines },
    props: {
        game: {
            type: Object,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        player: {
            type: Boolean,
            default: false,
        },
        teamName: {
            type: String,
            default: '',
        },
        cash: {
            type: Number,
            default: NaN,
        },
    },
    data() {
        return {
            selectedPlantUnit: null,
            pinnedPlots: [],
            canvasEvents: [
                'dragEnd',
                'hoverNode',
                'blurNode',
                'selectNode',
                'deselectNode',
                'doubleClick',
            ],
            visNetworkKey: 0,
            layout: {
                autoResize: true,
                width: '100%',
                hight: '100%',
                images: images,
                nodes: [],
                edges: [],
                options: {
                    autoResize: true,
                    nodes: {
                        size: 20,
                        font: {
                            color: 'gray',
                            size: 16,
                        },
                    },
                    edges: {
                        color: 'black',
                        arrows: {
                            to: {
                                enabled: true,
                                scaleFactor: 0.5,
                            },
                        },
                        arrowStrikethrough: false,
                        smooth: {
                            enabled: false,
                        },
                        font: {
                            size: 14,
                        },
                        width: 2,
                        smooth: {
                            enabled: true,
                            type: 'straightCross',
                            roundness: 0.2,
                        },
                    },
                    physics: {
                        enabled: false,
                    },
                    interaction: {
                        hover: true,
                        keyboard: {
                            enabled: true,
                        },
                    },
                },
            },
        }
    },
    computed: {
        plant: function () {
            if (this.player) {
                return this.game.plant
            }

            return this.game.simulation.template.plant
        },
        isGamePlay: function () {
            return this.$route.name == 'gamePlay'
        },
        selectedPlantUnitParamsComponent: function () {
            return `${this.selectedPlantUnit.class}-params`
        },
        selectedPlantUnitPlotsComponent: function () {
            return `${this.selectedPlantUnit.class}-plots`
        },
        selectedPlantUnitStats: function () {
            return {
                unit: this.selectedPlantUnit,
                stats: this.getUnitStats(this.selectedPlantUnit),
            }
        },
        plotsModalTitle: function () {
            return (this.selectedPlantUnit ?? {}).id ?? 'Statistics'
        },
    },
    methods: {
        removeNestedProps(obj) {
            let prunedObj = {}
            for (const prop in obj) {
                if (typeof obj[prop] !== 'object') {
                    prunedObj[prop] = obj[prop]
                }
            }
            return prunedObj
        },
        addDays: addDays,
        redrawnetwork() {
            this.initVisNetwork()
            this.loadNodePositions()
        },
        deletedunit() {
            this.selectedPlantUnit = null
            this.redrawnetwork()
        },
        home() {
            this.selectedPlantUnit = null
        },
        canvasKeyUp(event) {
            if (event.key === 'Delete') {
                this.$refs.unitParam.$refs.deleteBtn.deleteUnit()
            }
        },
        dragStart(event, item) {
            event.dataTransfer.dropEffect = 'move'
            event.dataTransfer.effectAllowed = 'move'
            event.dataTransfer.setData('type', item)
        },
        onDrop(event) {
            event.preventDefault()
            event.stopPropagation()

            const dropName = event.dataTransfer.getData('type')
            const unitType = dropName.toLowerCase()
            const boundingBox = event.target.getBoundingClientRect()
            const pos = this.$refs.network.domToCanvas({
                x: event.clientX - boundingBox.left,
                y: event.clientY - boundingBox.top,
            })

            const newUnit = Object.values(
                gameTemplate['simulation']['template']['plant']['units']
            ).find((u) => u.class === unitType)

            // remove references that are defined in the default game
            const params = ['office', 'warehouse', 'supplier']
            params.forEach((param) => {
                if (typeof newUnit.params[param] !== 'undefined') {
                    newUnit.params[param] = null
                }
            })

            const newUnitLayoutParams = {
                customer: {
                    order_interarrival_time_normal_avg: {
                        show: true,
                        edit: false,
                    },
                    order_interarrival_time_normal_sigma: {
                        show: true,
                        edit: false,
                    },
                    order_interarrival_time_constant: {
                        show: true,
                        edit: false,
                    },
                    order_interarrival_time_exponential_lambda: {
                        show: true,
                        edit: false,
                    },
                    order_ammount_normal_avg: { show: true, edit: false },
                    order_ammount_normal_sigma: { show: true, edit: false },
                    order_ammount_constant: { show: true, edit: false },
                },
                matcher: {
                    queue_policy: { show: true, edit: true },
                    shortage_cost: { show: true, edit: false },
                },
                office: {
                    queue_policy: { show: true, edit: true },
                    lot_size: { show: true, edit: true },
                    contract: { show: true, edit: true },
                },
                shipment: {
                    queue_policy: { show: true, edit: true },
                    unit_storage_cost: { show: true, edit: false },
                },
                supplier: {
                    delivery_time_constant: { show: true, edit: false },
                    delivery_time_normal_avg: { show: true, edit: false },
                    delivery_time_normal_sigma: { show: true, edit: false },
                    lead_time: { show: true, edit: false },
                    contract: { show: true, edit: false },
                },
                warehouse: {
                    inventory: { show: true, edit: false },
                    order_size: { show: true, edit: true },
                    reorder_point: { show: true, edit: true },
                    unit_storage_cost: { show: true, edit: false },
                },
                workshop: {
                    breakdown: { show: true, edit: false },
                    lot_setup_time_type: { show: true, edit: false },
                    lot_setup_time_constant: { show: true, edit: false },
                    lot_setup_time_avg: { show: true, edit: false },
                    lot_setup_time_sigma: { show: true, edit: false },
                    machine_quantity: { show: true, edit: true },
                    queue_policy: { show: true, edit: true },
                    item_processing_time_avg: { show: true, edit: false },
                    item_processing_time_sigma: { show: true, edit: false },
                    item_processing_time_constant: { show: true, edit: false },
                    item_processing_time_type: { show: true, edit: false },
                },
            }[unitType]

            // find a 'free' name
            let unitName
            if (typeof this.plant.units[dropName] === 'undefined') {
                unitName = dropName
            } else {
                let i = 0
                do {
                    i += 1
                    unitName = dropName + ' ' + i
                } while (typeof this.plant.units[unitName] !== 'undefined')
            }

            if (['office'].includes(unitType)) {
                this.plant.pipeline['Product XYZ'].unshift({ unit: unitName }) // prepend
            } else if (['office', 'matcher', 'workshop'].includes(unitType)) {
                // insert before the shipment
                const units = this.plant.units
                const index = this.plant.pipeline['Product XYZ'].findIndex(
                    (u) => units[u.unit].class === 'shipment'
                )
                this.plant.pipeline['Product XYZ'].splice(
                    index < 0 ? 0 : index,
                    0,
                    {
                        unit: unitName,
                    }
                )
            } else if (['shipment'].includes(unitType)) {
                this.plant.pipeline['Product XYZ'].push({ unit: unitName })
            }

            Vue.set(this.game.layout, unitName, {
                position: pos,
                params: newUnitLayoutParams,
            })
            Vue.set(this.plant.units, unitName, newUnit)

            this.redrawnetwork()
        },
        getRandomColor() {
            return '#' + Math.floor(Math.random() * 16777215).toString(16)
        },
        saveNodePositions() {
            this.$refs.network.nodes.forEach((node) => {
                let pos = this.$refs.network.canvasToDom(
                    this.$refs.network.getPositions(node.id)[node.id]
                )
                this.game.layout[node.id] ??= {}
                this.game.layout[node.id].position = { x: pos.x, y: pos.y }
            })
        },
        loadNodePositions() {
            this.layout.nodes.forEach((node) => {
                this.game.layout[node.id] ??= {}

                if (!this.game.layout[node.id].position) {
                    return
                }

                node.x = this.game.layout[node.id].position.x
                node.y = this.game.layout[node.id].position.y
            })
        },
        initVisNetwork() {
            // clear nodes
            this.layout.nodes = []
            this.layout.edges = []

            // add the nodes
            for (let unitName in this.plant.units) {
                const unit = this.plant.units[unitName]

                this.layout.nodes.push({
                    id: unitName,
                    label: unitName,
                    shape: 'image',
                    image: this.layout.images[unit.class].image,
                })
            }

            // add pipeline edges
            for (let pipelineName in this.plant.pipeline) {
                const pipeline = this.plant.pipeline[pipelineName]
                for (let i = 0; i < pipeline.length - 1; i++) {
                    this.layout.edges.push({
                        from: pipeline[i].unit,
                        to: pipeline[i + 1].unit,
                        label: 'Lots',
                        width: (this.layout.options.edges.width ?? 1) + 2,
                        color: 'salmon',
                    })
                }
            }

            // add dep. edges
            for (let unitName in this.plant.units) {
                const unit = this.plant.units[unitName]
                switch (unit.class) {
                    case 'customer':
                        if (typeof unit.params.office !== 'undefined') {
                            this.layout.edges.push({
                                from: unitName,
                                to: unit.params.office,
                                label: 'Orders',
                            })
                        }
                        break
                    case 'matcher':
                        if (typeof unit.params.warehouse !== 'undefined') {
                            this.layout.edges.push({
                                from: unit.params.warehouse,
                                to: unitName,
                                label: 'Inventory',
                            })
                        }
                        break
                    case 'warehouse':
                        if (typeof unit.params.supplier !== 'undefined') {
                            this.layout.edges.push({
                                from: unitName,
                                to: unit.params.supplier,
                                label: 'Orders',
                            })
                        }
                        break
                }
            }
        },
        fitVisNetwork() {
            this.$refs.network.redraw()
            this.$refs.network.fit({
                animation: {
                    duration: 1000,
                    easingFunction: 'easeOutQuint',
                },
            })
        },
        createVisNetwork() {
            this.initVisNetwork()
            this.loadNodePositions()
            this.$nextTick(() => {
                this.fitVisNetwork()
            })
        },
        reloadVisNetwork() {
            this.visNetworkKey += 1
            this.selectedPlantUnit = null
            this.$nextTick(() => {
                this.fitVisNetwork()
            })
        },
        onNodeHover(e) {
            this.$refs.network.network.canvas.body.container.style.cursor =
                'grabbing'
        },
        onNodeBlur(e) {
            this.$refs.network.network.canvas.body.container.style.cursor =
                'default'
        },
        onNodeDragEnd(e) {
            this.saveNodePositions()
        },
        getUnitStats(unit) {
            if (!this.isGamePlay) {
                return []
            }

            let stats = []

            for (var s of this.plant.stats) {
                var data = s.units[unit.id]

                if (unit.class == 'shipment') {
                    data = { ...s.plant, ...data }
                }

                stats.push(data)
            }

            return stats
        },
        getPlantUnit(id) {
            let unit = {
                id: id,
                layout: this.game.layout[id],
                gameStatus: this.game.status,
                game: this.game,
                ...this.plant.units[id],
            }

            if (this.$route.name == 'gamePlay') {
                unit.teamId = this.$route.params.teamId
                unit.stats = this.plant.stats ?? {}
            }

            return unit
        },
        onNodeSelect(e) {
            this.selectedPlantUnit = this.getPlantUnit(e.nodes[0])
        },
        onNodeDeselect(e) {
            this.selectedPlantUnit = null
        },
        onCanvasDoubleClick(e) {
            if (!this.selectedPlantUnit || !this.isGamePlay) {
                return
            }

            const blacklist = ['customer']

            if (blacklist.includes(this.selectedPlantUnit.class)) {
                return
            }

            this.$refs.showPlotsModal.show()
        },
        onPlantUnitUpdate(unit) {
            this.plant.units[unit.id].params = unit.params
            this.game.layout[unit.id] = unit.layout
        },
        onUserActionError() {
            this.$emit('game-reload')
        },
        onUserActionSubmitted() {
            this.$emit('game-reload')
        },
        closePlotsModal() {
            this.$refs.showPlotsModal.hide()
        },
        pinPlotsModal() {
            this.$refs.showPlotsModal.hide()

            if (
                !this.pinnedPlots.find((p) => p.id == this.selectedPlantUnit.id)
            ) {
                this.pinnedPlots.push({
                    id: this.selectedPlantUnit.id,
                    data: this.selectedPlantUnitStats,
                    component: this.selectedPlantUnitPlotsComponent,
                })
            }
            console.log(this.pinnedPlots)
        },
        closePinnedPlot(id) {
            this.pinnedPlots = this.pinnedPlots.filter((plot) => plot.id !== id)
        },
        updatePinnedPlots() {
            this.pinnedPlots.forEach((plot) => {
                const unit = this.getPlantUnit(plot.id)
                plot.data = { unit: unit, stats: this.getUnitStats(unit) }
            })
        },
    },
    created() {
        window.addEventListener('resize', this.fitVisNetwork)
    },
    destroyed() {
        window.removeEventListener('resize', this.fitVisNetwork)
    },
    mounted() {
        if (!!this.game) {
            this.createVisNetwork()
        }
    },
    watch: {
        game: function () {
            if (!this.game) {
                return
            }

            this.createVisNetwork()
            this.updatePinnedPlots()

            this.$nextTick(() => {
                if (this.game.team.plant === null) {
                    this.$refs.network.selectNodes([])
                    this.selectedPlantUnit = null
                }
            })
        },
    },
}
</script>
<style>
.grabbing * {
    cursor: grabbing;
}

#canvas {
    width: 100%;
    height: 400px;
    background-color: #fafafa;
    background-size: 20px 20px;
    display: flex;
    background-image: radial-gradient(circle, gray 1px, rgba(0, 0, 0, 0) 1px);
}

.factory-unit-image {
    cursor: grabbing;
}

.card-header,
.title {
    text-transform: capitalize;
}

.input-group-text {
    font-size: 100%;
}

.sticky-top {
    top: 0.5em;
}

.scroll {
    overflow-y: auto;
    max-height: 100vh;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

.scrollbar-hidden {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
</style>
