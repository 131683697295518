<template>
    <div v-if="showQueuePolicy" class="mt-2">
        <label>Queue policy:</label>

        <b-input-group>
            <b-input-group-prepend is-text>
                <b-icon icon="arrow-bar-right" aria-hidden="true"></b-icon>
            </b-input-group-prepend>

            <b-form-select
                class="text-uppercase"
                v-model="data.params[paramName]"
                :options="['fifo', 'lifo']"
                :disabled="!editQueuePolicyAllowed"
            >
            </b-form-select>

            <b-input-group-append>
                <b-input-group-text v-if="!isGamePlay">
                    <b-form-checkbox
                        class="mr-n2"
                        v-model="data.layout.params[paramName].show"
                        v-b-popover.hover.top="'Show'"
                        @change="onShowToggle"
                        switch
                    >
                    </b-form-checkbox>
                </b-input-group-text>

                <b-input-group-text v-if="!isGamePlay">
                    <b-form-checkbox
                        class="mr-n2"
                        v-model="data.layout.params[paramName].edit"
                        v-b-popover.hover.top="'Edit'"
                        @change="onEditToggle"
                        switch
                    >
                    </b-form-checkbox>
                </b-input-group-text>

                <b-button
                    v-if="isGamePlay"
                    variant="success"
                    :disabled="!editQueuePolicyAllowed || waitingSubmitResponse"
                    @click="$emit('contractChange')"
                >
                    Submit
                </b-button>
            </b-input-group-append>
        </b-input-group>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
        paramName: {
            type: String,
            required: true,
        },
        waitingSubmitResponse: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        isGamePlay: function () {
            return this.$route.name == 'gamePlay'
        },
        showQueuePolicy() {
            return (
                this.data.layout.params[this.paramName].show || !this.isGamePlay
            )
        },
        editQueuePolicyAllowed: function () {
            if (!this.isGamePlay) {
                return true
            }

            return this.data.layout.params[this.paramName].edit
        },
    },
    methods: {
        onShowToggle: function (show) {
            if (!show) {
                this.data.layout.params[this.paramName].edit = false
                this.$forceUpdate()
            }
        },
        onEditToggle: function (edit) {
            if (edit) {
                this.data.layout.params[this.paramName].show = true
                this.$forceUpdate()
            }
        },
    },
}
</script>
