<template>
    <div v-if="ready" class="container">
        <bar-chart
            :width="700"
            :height="250"
            v-bind:chartData="states.chartData"
            :options="states.options"
        />

        <line-chart
            :width="700"
            :height="250"
            v-bind:chartData="queue.chartData"
            :options="queue.options"
        />
    </div>
</template>
<script>
import BarChart from './Plots/BarChart.vue'
import LineChart from './Plots/LineChart.vue'
import mixins from './Plots/mixins'

export default {
    components: { BarChart, LineChart },
    mixins: [mixins.plotDataMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        maintainAspectRatio: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            states: {
                chartData: null,
                recentDaysNumber: 20,
                options: {
                    title: {
                        display: true,
                        text: 'Daily machine states',
                    },
                    scales: {
                        yAxes: [
                            {
                                stacked: true,
                                ticks: {
                                    beginAtZero: true,
                                    maxTicksLimit: 10,
                                    max: 100,
                                },
                                gridLines: {
                                    display: true,
                                },
                                scaleLabel: {
                                    display: true,
                                    fontStyle: 'italic',
                                    labelString: 'Time in each State [%]',
                                },
                            },
                        ],
                        xAxes: [
                            {
                                stacked: true,
                                gridLines: {
                                    display: false,
                                },
                                scaleLabel: {
                                    display: true,
                                    fontStyle: 'italic',
                                    labelString: 'Day',
                                },
                            },
                        ],
                    },
                    responsive: true,
                    maintainAspectRatio: this.maintainAspectRatio,
                },
            },
            queue: {
                chartData: null,
                options: {
                    title: {
                        display: true,
                        text: 'Items waiting to be processed',
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    maxTicksLimit: 10,
                                },
                                gridLines: {
                                    display: true,
                                },
                                scaleLabel: {
                                    display: true,
                                    fontStyle: 'italic',
                                    labelString: 'Queue size [items]',
                                },
                            },
                        ],
                        xAxes: [
                            {
                                gridLines: {
                                    display: false,
                                },
                                scaleLabel: {
                                    display: true,
                                    fontStyle: 'italic',
                                    labelString: 'Day',
                                },
                            },
                        ],
                    },
                    legend: {
                        display: true,
                    },
                    responsive: true,
                    maintainAspectRatio: this.maintainAspectRatio,
                },
            },
        }
    },
    computed: {
        ready: function () {
            return !!this.states.chartData && !!this.queue.chartData
        },
    },
    methods: {
        loadMachineStatesData() {
            let { stats, labels } = this.getRecentStats()

            const states = [
                { name: 'Busy', color: 'rgba(0, 0, 255, 0.5)' },
                { name: 'Ready', color: 'rgba(0, 128, 0, 0.5)' },
                { name: 'Broken', color: 'rgba(255, 0, 0, 0.5)' },
            ]

            var datasets = []

            states.forEach((state) => {
                datasets.push({
                    label: state.name,
                    backgroundColor: state.color,
                    data: stats.map((s) => s[state.name.toLowerCase()]),
                })
            })

            this.states.chartData = { labels: labels, datasets: datasets }
        },
        loadQueueSizeData() {
            let { stats, labels } = this.getRecentStats()
            this.queue.chartData = {
                labels: labels,
                datasets: [
                    {
                        label: 'Queue size at the end of the day',
                        data: stats.map((s) => s['queue size last']),
                        backgroundColor: 'rgb(0, 128, 128, 0.5)',
                        steppedLine: 'middle',
                        pointRadius: 0,
                    },
                    {
                        label: 'Maximum queue size of the day',
                        data: stats.map((s) => s['queue size max']),
                        backgroundColor: 'rgb(0, 128, 0, 0.5)',
                        steppedLine: 'middle',
                        hidden: true,
                        pointRadius: 0,
                    },
                    {
                        label: 'Average Queue size of the day',
                        data: stats.map((s) => s['queue size avg']),
                        backgroundColor: 'rgb(128, 0, 0, 0.5)',
                        steppedLine: 'middle',
                        hidden: true,
                        pointRadius: 0,
                    },
                ],
            }
        },
        initChartData() {
            this.loadMachineStatesData()
            this.loadQueueSizeData()
        },
    },
    mounted() {
        this.initChartData()
    },
    watch: {
        data: function () {
            if (!!this.data) {
                this.initChartData()
            }
        },
    },
}
</script>
<style>
.pin-plot-btn {
    padding: 0.375rem 0.75rem;
}
</style>
