<template>
    <div v-if="ready" class="container">
        <div class="row row-cols-1">
            <div class="col">
                <data-export-button
                    class="rounded border border-info"
                    name="Supplier orders"
                    :data="orders"
                >
                    Orders
                </data-export-button>
            </div>
        </div>
        <bar-chart
            :width="700"
            :height="450"
            v-bind:chartData="queueChartData"
            :options="queueOptions"
        />
    </div>
</template>
<script>
import BarChart from './Plots/BarChart.vue'
import DataExportButton from './Plots/DataExportButton.vue'
import mixins from './Plots/mixins'

export default {
    components: { BarChart, DataExportButton },
    mixins: [mixins.plotDataMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        maintainAspectRatio: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            queueChartData: null,
            queueOptions: {
                title: {
                    display: true,
                    text: 'Incoming orders',
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                            gridLines: {
                                display: true,
                            },
                            scaleLabel: {
                                display: true,
                                fontStyle: 'italic',
                                labelString: 'Amount of orders [boxes]',
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            scaleLabel: {
                                display: true,
                                fontStyle: 'italic',
                                labelString: 'Day',
                            },
                        },
                    ],
                },
                legend: {
                    display: false,
                },
                responsive: true,
                maintainAspectRatio: this.maintainAspectRatio,
            },
        }
    },
    computed: {
        ready() {
            return !!this.queueChartData
        },
        orders() {
            return this.data.stats.flatMap((x) =>
                x.orders.map((order) => {
                    return {
                        'accepted at': order.accepted_at_formatted,
                        'finished at': order.finished_at_formatted,
                        'fixed price': order.fix_price,
                        'batch price': order.batch_price,
                        'inventory - backlog before': order.inventory_before,
                        'inventory - backlog after': order.inventory_after,
                    }
                })
            )
        },
    },
    methods: {
        initQueueChartData() {
            let { stats, labels } = this.getRecentStats()
            this.queueChartData = {
                labels: labels,
                datasets: [
                    {
                        label: 'Queue size',
                        data: stats.map((s) => s['queue size']),
                        backgroundColor: 'rgba(0, 0, 255, 0.5)',
                        steppedLine: 'middle',
                        pointRadius: 0,
                    },
                ],
            }
        },
        initChatData() {
            this.initQueueChartData()
        },
    },
    mounted() {
        this.initChatData()
    },
    watch: {
        data: function () {
            if (!!this.data) {
                this.initChatData()
            }
        },
    },
}
</script>
