<template>
    <div class="card shadow-sm">
        <div class="card-header">
            Game
            <span class="float-right" v-if="teamName !== ''">
                Team name: {{ teamName }}
            </span>
        </div>
        <div class="card-body text-muted font-weight-light">
            <b-row>
                <b-col>
                    <p>Progress:</p>
                    <b-progress
                        class="mt-1"
                        :value="currentDay"
                        :max="totalDays"
                        :variant="progressBarVariant"
                        animated
                    >
                    </b-progress>
                    <div class="float-right mt-1">
                        <span class="small font-weight-light">
                            {{ currentDay }} of {{ totalDays }} days ({{
                                elapsedTimePercent
                            }}%)
                        </span>
                    </div>
                </b-col>
            </b-row>

            <b-row v-if="!isNaN(cash)" class="mt-3">
                <b-col>
                    <span>Cash:</span>
                    <span class="float-right text-monospace">{{
                        toFormattedCurrency(cash)
                    }}</span>
                </b-col>
            </b-row>

            <b-row v-if="game" class="mt-3">
                <b-col>
                    <div class="mb-3">
                        <span>Leaders</span>
                        <span class="float-right">
                            <data-export-button
                                name="leaderboard.csv"
                                :data="teams"
                                :fields="leaderboard_fields"
                            >
                            </data-export-button>
                        </span>
                    </div>
                    <ol>
                        <li
                            v-for="team in teams"
                            :key="team.id"
                            v-bind:class="{
                                'text-primary': team.name === teamName,
                            }"
                        >
                            <template v-if="isDesigner && isOwner">
                                <a :href="'/game/play/' + team.id">
                                    <span>{{ team.name }}</span>
                                    <span v-if="game.status == 'ASYNC'">
                                        (day
                                        {{
                                            team.team_progress
                                                ? team.team_progress.day + 1
                                                : 'N/A'
                                        }})</span
                                    >
                                    <span class="float-right text-monospace">{{
                                        toFormattedCurrency(team.cash)
                                    }}</span>
                                </a>
                            </template>
                            <template v-else>
                                <span>{{ team.name }}</span>
                                <span v-if="game.status == 'ASYNC'">
                                    (day
                                    {{
                                        team.team_progress
                                            ? team.team_progress.day + 1
                                            : 'N/A'
                                    }})</span
                                >
                                <span class="float-right text-monospace">{{
                                    toFormattedCurrency(team.cash)
                                }}</span>
                            </template>
                        </li>
                    </ol>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
import { gameStatusBadgeMap } from '@/views'
import { toFormattedCurrency } from '../../../services/formatting'
import DataExportButton from '../Units/Plots/DataExportButton.vue'
import { GameStatus } from '../../../views'

export default {
    components: { DataExportButton },
    props: {
        game: {
            type: Object,
            required: true,
        },
        teamName: {
            type: String,
            default: '',
        },
        cash: {
            type: Number,
            default: NaN,
        },
    },
    data() {
        return {
            leaderboard_fields: {
                name: 'name',
                cash: 'cash',
                bracket: 'bracket',
            },
        }
    },
    methods: {
        toFormattedCurrency: toFormattedCurrency,
    },
    computed: {
        isDesigner() {
            return this.$store.getters.userHasRole('DESIGNER')
        },
        isOwner() {
            return this.$store.state.auth.user.id === this.game.owner.id
        },
        isGamePlay: function () {
            return this.$route.name == 'gamePlay'
        },
        currentDay: function () {
            if (!this.game) {
                return 0
            }

            if (this.game.team) {
                return this.game.team.team_progress.day + 1
            } else {
                return !this.game.progress ? 0 : this.game.progress.day + 1
            }
        },
        totalDays: function () {
            if (!this.game) {
                return 0
            }

            if (this.game.team) {
                return this.game.team.team_progress.total_days
            } else {
                return !this.game.progress ? 0 : this.game.progress.total_days
            }
        },
        elapsedTimePercent: function () {
            if (!this.game || !this.game.progress) {
                return 0
            }

            return Math.round((this.currentDay / this.totalDays) * 100)
        },
        progressBarVariant: function () {
            if (!this.game) {
                return 'danger'
            }

            if (
                this.game.status == GameStatus.ASYNC &&
                this.elapsedTimePercent >= 99.9
            ) {
                return 'success'
            }
            return gameStatusBadgeMap[this.game.status]
        },
        teams: function () {
            if (
                this.game.leaderboard === false &&
                !(this.isDesigner && this.isOwner)
            ) {
                const tn = this.teamName

                return this.game.teams
                    ? this.game.teams.filter((team) => {
                          return team.name === tn
                      })
                    : []
            }
            if (typeof this.game.teams === 'undefined') {
                return []
            } else {
                return [...this.game.teams].sort(function (a, b) {
                    if (a.cash == b.cash) {
                        // if cash is same use name
                        // shorter first
                        if (a.name.length < b.name.length) {
                            return -1
                        } else if (a.name.length > b.name.length) {
                            return 1
                        }
                        // sort alphabet
                        if (a.name < b.name) {
                            return -1
                        } else if (a.name > b.name) {
                            return 1
                        } else {
                            return 0
                        }
                    } else {
                        return b.cash - a.cash // chose with higher cash
                    }
                })
            }
        },
    },
}
</script>
