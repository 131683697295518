<template>
    <div v-if="this.$route.name !== 'gamePlay'" class="float-right">
        <b-button size="sm" variant="danger" @click="deleteUnit">
            <b-icon icon="trash" v-b-popover.hover.top="'Shortcut: del'">
            </b-icon>
        </b-button>
    </div>
    <div
        v-else
        class="float-right"
        v-b-popover.hover.top="'Return to leaderboard'"
        @click="$emit('home')"
    >
        <b-button size="sm">
            <b-icon icon="house-door"></b-icon>
        </b-button>
    </div>
</template>
<script>
import { changeValueOfDependents } from './index'
export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    methods: {
        deleteUnit() {
            const id = this.data.id
            const gameTemplate = this.data.game.simulation.template
            const units = gameTemplate.plant.units
            const pipelines = gameTemplate.plant.pipeline
            const layout = this.data.game.layout

            // remove the unit from the layout
            delete layout[id]

            // remove the unit from the pipeline
            for (let pipelineName in pipelines) {
                pipelines[pipelineName] = pipelines[pipelineName].filter(
                    (obj) => {
                        return obj.unit !== id
                    }
                )
            }

            // remove the unit from it deps.
            changeValueOfDependents(units, this.data.class, id, null)

            // remove the unit from 'units'
            delete units[id]

            // tell parent
            this.$emit('deletedunit')
        },
    },
}
</script>
