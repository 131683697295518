<template>
    <div v-if="ready" class="container">
        <bar-chart
            :width="700"
            :height="450"
            v-bind:chartData="incoming.chartData"
            :options="incoming.options"
        />
    </div>
</template>
<script>
import BarChart from './Plots/BarChart.vue'
import mixins from './Plots/mixins'

export default {
    components: { BarChart },
    mixins: [mixins.plotDataMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        maintainAspectRatio: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            incoming: {
                chartData: null,
                options: {
                    title: {
                        display: true,
                        text: 'Incoming orders',
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                },
                                gridLines: {
                                    display: true,
                                },
                                scaleLabel: {
                                    display: true,
                                    fontStyle: 'italic',
                                    labelString: 'Number of sales [boxes]',
                                },
                            },
                        ],
                        xAxes: [
                            {
                                gridLines: {
                                    display: false,
                                },
                                scaleLabel: {
                                    display: true,
                                    fontStyle: 'italic',
                                    labelString: 'Day',
                                },
                            },
                        ],
                    },
                    legend: {
                        display: false,
                    },
                    responsive: true,
                    maintainAspectRatio: this.maintainAspectRatio,
                },
            },
        }
    },
    computed: {
        ready: function () {
            return !!this.incoming.chartData
        },
    },
    methods: {
        loadIncomingOrders() {
            let { stats, labels } = this.getRecentStats()
            this.incoming.chartData = {
                labels: labels,
                datasets: [
                    {
                        label: 'Orders',
                        data: stats.map((s) => s['incoming orders']),
                        backgroundColor: 'rgb(0, 128, 128, 0.5)',
                        steppedLine: 'middle',
                        pointRadius: 0,
                    },
                ],
            }
        },
        initChartData() {
            this.loadIncomingOrders()
        },
    },
    mounted() {
        this.initChartData()
    },
    watch: {
        data: function () {
            if (!!this.data) {
                this.initChartData()
            }
        },
    },
}
</script>
