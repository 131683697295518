function encodeQueryData(data) {
    const ret = []

    for (let key in data) {
        const val = data[key]

        if (!!val) {
            ret.push(encodeURIComponent(key) + '=' + encodeURIComponent(val))
        }
    }

    return ret.join('&')
}

export { encodeQueryData }
